import { StatusColumnContainer } from './styles';
import { Typography } from '@mui/material';
import i18n from '../../translate/i18n';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';

export interface StatusColumnContainerProps {
  status: string;
  colors: any;
}

const StatusColumn = ({ status, colors }: StatusColumnContainerProps) => {
  return (
    <StatusColumnContainer status={status} colors={colors}>
      {status === 'enviada' && <SendOutlinedIcon />}
      {status === 'agendada' && <ScheduleSendOutlinedIcon />}
      <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
        {status === 'enviada' &&
          i18n.t('pages.messageQueues.datagrid.columns.statusTypes.send')}
        {status === 'agendada' &&
          i18n.t('pages.messageQueues.datagrid.columns.statusTypes.await')}
      </Typography>
    </StatusColumnContainer>
  );
};

export default StatusColumn;
