import { DataGrid, GridValidRowModel, GridColumns } from '@mui/x-data-grid';
import i18n from '../../translate/i18n';

export interface DefaultGridProps {
  rows: GridValidRowModel[];
  columns: GridColumns<GridValidRowModel>;
}

export const DefaultGrid = ({ rows, columns }: DefaultGridProps) => {
  const localeLabels = {
    MuiTablePagination: {
      labelRowsPerPage: i18n.t('datagrid.default.labelRowsPerPage'),
      labelDisplayedRows: ({ from, to, count }: any) =>
        i18n.t('datagrid.default.labelDisplayedRows', { from, to, count }),
    },
  };
  return <DataGrid rows={rows} columns={columns} localeText={localeLabels} />;
};

// noRowsLabel: i18n.t('datagrid.default.noRowsLabel'),
// noResultsOverlayLabel: i18n.t('datagrid.default.noResultsOverlayLabel'),
