import { HeaderLogoContainer } from './styles';
import { Box, IconButton, Typography } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import 'react-pro-sidebar/dist/css/styles.css';
import i18n from '../../translate/i18n';

export interface HeaderLogoProps {
  color: string;
  isCollapsed: boolean;
  handleClick: () => void;
}

const HeaderLogo = ({ isCollapsed, handleClick, color }: HeaderLogoProps) => {
  return (
    <HeaderLogoContainer
      onClick={handleClick}
      icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
      color={color}
    >
      {!isCollapsed && (
        <Box className="box">
          <Typography variant="h3" color={color}>
            {i18n.t('sidebarMenu.projectName')}
          </Typography>
          <IconButton onClick={handleClick}>
            <MenuOutlinedIcon />
          </IconButton>
        </Box>
      )}
    </HeaderLogoContainer>
  );
};

export default HeaderLogo;
