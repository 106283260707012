import { AcessColumnContainer } from './styles';
import { Typography } from '@mui/material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import i18n from '../../translate/i18n';
import { UserType } from '../../@types/models';

export interface AcessColumnContainerProps {
  access: UserType;
  colors: any;
}

const AcessColumn = ({ access, colors }: AcessColumnContainerProps) => {
  return (
    <AcessColumnContainer access={access} colors={colors}>
      {(access === 'AdminAssinatura' || access === 'AdminGeral') && (
        <AdminPanelSettingsOutlinedIcon />
      )}
      {(access === 'ComCelular' || access === 'SemCelular') && (
        <LockOpenOutlinedIcon />
      )}
      <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
        {(access === 'AdminAssinatura' || access === 'AdminGeral') &&
          i18n.t('pages.users.datagrid.columns.acesss_level.admin')}
        {(access === 'ComCelular' || access === 'SemCelular') &&
          i18n.t('pages.users.datagrid.columns.acesss_level.users')}
      </Typography>
    </AcessColumnContainer>
  );
};

export default AcessColumn;
