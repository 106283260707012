import { MessageModel, MessageViewModel } from '../@types/models';
import { toMessage } from '../utils/models-utils';
import { Api } from './api';

const CONTROLLER = 'Mensagem';

export const list = async (): Promise<MessageViewModel[]> => {
  return await Api.get(
    `${CONTROLLER}/fila?NumeroDaPagina=0&TamanhoDaPagina=1500&ExibirTodasDaAssinatura=true`,
  ).then((res) => {
    return res.data.itens.map((x: any) => {
      return {
        id: x.id,
        user: x.usuario,
        status: x.status,
        sendTime: x.horarioAgendamento,
        numberContacts: x.quantidadeContatos,
        isTemplate: x.mensagemPorTemplate,
        template: x.template,
      } as MessageViewModel;
    });
  });
};

export const getById = async (id: string): Promise<MessageModel> => {
  return await Api.get(`${CONTROLLER}/${id}`).then((res) => {
    return toMessage(res.data);
  });
};

export const create = async (
  contacts: string[],
  sendTime: Date,
  delay: number,
  templateId?: string,
  text?: string,
): Promise<string> => {
  return await Api.post(`${CONTROLLER}`, {
    contatoIds: contacts,
    enviarEm: sendTime,
    segundosDelay: delay,
    templateId,
    texto: text,
  }).then((res) => res.data);
};

export const update = async (
  id: string,
  contacts: string[],
  sendTime: Date,
  delay: number,
  templateId?: string,
  text?: string,
): Promise<boolean> => {
  return await Api.put(CONTROLLER, {
    mensagemId: id,
    contatoIds: contacts,
    enviarEm: sendTime,
    segundosDelay: delay,
    templateId,
    texto: text,
  }).then(() => {
    return true;
  });
};

export const remove = async (id: string): Promise<boolean> => {
  return await Api.delete(`${CONTROLLER}/${id}`).then(() => {
    return true;
  });
};
