import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { AccountCardMenu } from '../AccountCardMenu/index';
import i18n from '../../translate/i18n';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

export const IconButtonAccount = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Tooltip title={i18n.t('topbarMenu.iconDark')} arrow>
      <>
        <IconButton
          id="account-card"
          aria-controls={open ? 'account-card' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <PersonOutlinedIcon />
        </IconButton>
        <AccountCardMenu
          openLink={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </>
    </Tooltip>
  );
};
