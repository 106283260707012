import { useContext } from 'react';
import { GlobalContext } from '../context/GlobalProvider';

export const useGlobalState = () => {
  const context = useContext(GlobalContext);

  if (context === undefined)
    throw new Error('AuthContext | Não está dentro do contexto de uso');

  return context;
};
