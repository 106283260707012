import { Box } from '@mui/material';
import styled from 'styled-components';

export interface SignInContainerProps {
  colors: any;
  theme: any;
}

export const SignContainer = styled(Box)<SignInContainerProps>`
  position: relative;
  overflow: hidden;
  /* background-color: ${({ colors }) => colors.blueAccent[600]}; */
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 100vw;
  height: 100vh;

  .banner-bg {
    position: absolute;
    width: 110%;
    height: 80vh;
    top: -30%;
    background-color: ${({ colors }) => colors.greenAccent[600]};
    border-radius: 0 0 0 130px;
    transform: rotate(-15deg);
  }

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .card-login {
    position: relative;
    background-color: ${({ colors }) => colors.primary[400]};
    width: 30rem;
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    box-shadow:
      15px 15px 10px rgba(0, 0, 0, 0.05),
      15px 10px 15px rgba(0, 0, 0, 0.025);
  }
`;
