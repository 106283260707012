import { MessageViewModel } from '../../@types/models';
import { DefaultGrid } from '../../components/DefaultGrid';
import { ActionButtonsColumn } from '../../components/DefaultGrid/ActionButtonsColumn';
import i18n from '../../translate/i18n';
import StatusColumn from './StatusColumn';

import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

export interface MessageGridProps {
  messages: MessageViewModel[];
  handleSelectedMessage: (id: string) => void;
  handleRemoveMessage: (id: string) => void;
}

export const MessagesGrid = ({
  messages,
  handleSelectedMessage,
  handleRemoveMessage,
}: MessageGridProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const editMessage = (item: any) => {
    handleSelectedMessage(item.id);
  };

  const deleteMessage = async (item: any) => {
    handleRemoveMessage(item.id);
  };

  const columns = [
    {
      field: 'status',
      flex: 1,
      headerName: i18n.t('pages.messages.datagrid.columns.status'),
      renderCell: ({ row: { status } }: any) => {
        return <StatusColumn status={status} colors={colors} />;
      },
    },
    {
      field: 'sendTime',
      flex: 1,
      headerName: i18n.t('pages.messages.datagrid.columns.sendTime'),
    },
    {
      field: 'numberContacts',
      flex: 1,
      headerName: i18n.t('pages.messages.datagrid.columns.numberContacts'),
    },
    {
      field: 'isTemplate',
      flex: 1,
      headerName: i18n.t('pages.messages.datagrid.columns.isTemplate'),
      renderCell: (params: any) => {
        return params.value ? 'Sim' : 'Não';
      },
    },
    {
      field: 'template',
      headerName: i18n.t('pages.messages.datagrid.columns.template'),
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <ActionButtonsColumn
            params={params}
            actionDelete={deleteMessage}
            actionEdit={editMessage}
          />
        );
      },
    },
  ];

  return <DefaultGrid rows={messages || []} columns={columns} />;
};
