import { Menu } from '@mui/material';
import styled from 'styled-components';

export interface CardMenuContentProps {
  colors: any;
}

export const AccountCardMenuContent = styled(Menu)<CardMenuContentProps>`
  display: flex;
  padding: 1.5rem;

  & .MuiMenu-root {
  }

  & .MuiPaper-root {
    background-color: ${({ colors }) => colors.primary[400]};
    top: 50px !important;

  }

  .greetings {
    width: 250px;
  }
`;
