export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const formatPhoneNumber = (x: string): string => {
  if (x.length === 13)
    return `+${x.slice(0, 2)} (${x.slice(2, 4)}) ${x.slice(4, 9)}-${x.slice(
      9,
      13,
    )}`;

  if (x.length === 11)
    return `+55 (${x.slice(0, 2)}) ${x.slice(2, 7)}-${x.slice(7, 11)}`;

  return x;
};
