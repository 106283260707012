import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { MessageTemplateModel, MessageVariable } from '../../@types/models';
import { MessageTemplatesFormContainer } from './styles';
import * as yup from 'yup';
import i18n from '../../translate/i18n';
import { Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';

import * as MessageTemplateService from '../../services/message-template.service';

import { tokens } from '../../theme';
import { useGlobalState } from '../../hooks/useGlobalState';
import { VariableSelectMenu } from './VariableSelectMenu';

export const MessageTemplateForm = ({
  message,
  action,
  closeForm,
}: {
  message: MessageTemplateModel;
  action: 'new' | 'edit';
  closeForm: (option: 'cancel' | 'save' | 'edit') => void;
}) => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const {
    showLoading,
    hideLoading,
    showErrorAlert,
    showSuccessAlert,
    handleResponseError,
  } = useGlobalState();

  const [lastCursorPos, setLastCursorPos] = useState<number | null>(null);
  const [variables, setVariables] = useState<MessageVariable[]>([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleLoadVariables = useCallback(async () => {
    await MessageTemplateService.getVariables()
      .then((data) => setVariables(data))
      .catch((err) => {
        const msg = handleResponseError(err);
        showErrorAlert(
          msg || i18n.t('pages.messageTemplates.alerts.variables.error'),
        );
      });
  }, [handleResponseError, showErrorAlert]);

  useEffect(() => {
    handleLoadVariables();
  }, [handleLoadVariables]);

  const handleAddSpecialContentToMessage = (
    item: string,
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const text: string = values.message;
    const before = text.substring(0, lastCursorPos || 0);
    const after = text.substring(lastCursorPos || 0);

    setFieldValue('message', `${before} ${item} ${after}`);
  };

  return (
    <MessageTemplatesFormContainer colors={colors}>
      <Box className="content" m={2}>
        <SectionTitle
          title={
            action === 'new'
              ? i18n.t('pages.messageTemplates.form.title.new')
              : i18n.t('pages.messageTemplates.form.title.edit')
          }
        />
        <Formik
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            showLoading();

            const handleSuccess = (message: string) => {
              setStatus({ success: true });
              setSubmitting(false);
              hideLoading();
              showSuccessAlert(message);
            };

            const handleError = (err: any, message: string) => {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              hideLoading();
              const msg = handleResponseError(err);
              showErrorAlert(msg || message);
            };

            try {
              if (action === 'new') {
                await MessageTemplateService.create(
                  values.name,
                  values.message,
                  values.delay,
                )
                  .then(() => {
                    handleSuccess(
                      i18n.t('pages.messageTemplates.alerts.save.success'),
                    );
                  })
                  .catch((err) => {
                    handleError(
                      err,
                      i18n.t('pages.messageTemplates.alerts.save.error'),
                    );
                  });
              } else {
                message.name = values.name;
                message.body = values.message;
                message.delay = values.delay;

                await MessageTemplateService.update(message)
                  .then(() => {
                    handleSuccess(
                      i18n.t('pages.messageTemplates.alerts.save.success'),
                    );
                  })
                  .catch((err) => {
                    handleError(
                      err,
                      i18n.t('pages.messageTemplates.alerts.save.error'),
                    );
                  });
              }
              closeForm(action === 'new' ? 'save' : 'edit');
            } catch (err: any) {
              handleError(
                err,
                action === 'new'
                  ? i18n.t('pages.messageTemplates.alerts.save.error')
                  : i18n.t('pages.messageTemplates.alerts.edit.error'),
              );
            }
          }}
          initialValues={{
            name: message.name || '',
            message: message.body || '',
            delay: message.delay || 0,
            submit: null,
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.messageTemplates.form.fields.name')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 3' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label={i18n.t('pages.messageTemplates.form.fields.delay')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.delay || 0}
                  name="delay"
                  error={!!touched.delay && !!errors.delay}
                  helperText={touched.delay && errors.delay}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  fullWidth
                  multiline
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.messageTemplates.form.fields.message')}
                  onBlur={(e) => {
                    handleBlur(e);
                    setLastCursorPos(e.target.selectionStart);
                  }}
                  onChange={handleChange}
                  value={values.message}
                  name="message"
                  error={!!touched.message && !!errors.message}
                  helperText={touched.message && errors.message}
                  sx={{ gridColumn: 'span 4' }}
                  rows={4}
                />
              </Box>
              <VariableSelectMenu
                variables={variables}
                handleSelectedVariable={(variable: MessageVariable) => {
                  handleAddSpecialContentToMessage(
                    variable.value,
                    values,
                    setFieldValue,
                  );
                }}
              />
              {/* <Box display="flex" justifyContent="space-between" mt="10px">
                <Button
                  className="btn-variable"
                  size="small"
                  onClick={() =>
                    handleAddSpecialContentToMessage(
                      '{teste}',
                      values,
                      setFieldValue,
                    )
                  }
                >
                  Inserir variável
                </Button>
              </Box> */}

              <Box display="flex" justifyContent="end" mt="20px" gap={2}>
                <Button
                  type="reset"
                  color="info"
                  variant="contained"
                  onClick={() => closeForm('cancel')}
                >
                  {i18n.t('pages.messageTemplates.form.buttons.cancel')}
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  {action === 'new'
                    ? i18n.t('pages.messageTemplates.form.buttons.new')
                    : i18n.t('pages.messageTemplates.form.buttons.edit')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </MessageTemplatesFormContainer>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required(i18n.t('validators.errors.required') ?? ''),
  message: yup.string().required(i18n.t('validators.errors.required') ?? ''),
  delay: yup.number().required(i18n.t('validators.errors.required') ?? ''),
});
