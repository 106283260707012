import { MessageTemplateModel } from '../../@types/models';
import { DefaultGrid } from '../../components/DefaultGrid';
import { ActionButtonsColumn } from '../../components/DefaultGrid/ActionButtonsColumn';
import i18n from '../../translate/i18n';

export interface MessageGridProps {
  messages: MessageTemplateModel[];
  handleSelectedMessage: (id: string) => void;
  handleRemoveMessage: (id: string) => void;
}

export const MessageTemplatesGrid = ({
  messages,
  handleSelectedMessage,
  handleRemoveMessage,
}: MessageGridProps) => {
  const editMessage = (item: any) => {
    handleSelectedMessage(item.id);
  };

  const deleteMessage = async (item: any) => {
    handleRemoveMessage(item.id);
  };

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('pages.messages.datagrid.columns.name'),
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <ActionButtonsColumn
            params={params}
            actionDelete={deleteMessage}
            actionEdit={editMessage}
          />
        );
      },
    },
  ];

  return <DefaultGrid rows={messages || []} columns={columns} />;
};
