import { Box } from '@mui/material';
import styled from 'styled-components';

export interface ContainerProps {
  colors: any;
}

export const MessageTemplatesContainer = styled(Box)<ContainerProps>`
margin: 0.2rem;
  height: 100%;

.content {
  margin: 1rem 0 0 0;
  height: 75vh;
  max-height: calc(100vh - 15rem);

  & .MuiDataGrid-root {
    border: none;
  }
  & .MuiDataGrid-cell {
    border-bottom: none;
  }
  & .name-column--cell {
    color: ${({ colors }) => colors.greenAccent[300]};
  }
  & .id-column--cell {
    padding-left: 10px;
  }
  & .MuiDataGrid-columnHeaders {
    background-color: ${({ colors }) => colors.blueAccent[700]};
    border-bottom: none;
  }
  & .MuiDataGrid-virtualScroller {
    background-color: ${({ colors }) => colors.primary[400]};
  }
  & .MuiDataGrid-footerContainer {
    border-top: none;
    background-color: ${({ colors }) => colors.blueAccent[700]},
  }
  & .MuiCheckbox-root {
    color: ${({ colors }) => colors.greenAccent[200]} !important;
  }
}

.controlBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
`;

export const MessageTemplatesFormContainer = styled(Box)<ContainerProps>`
height: 100%;
width: 40rem;

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.content {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.btn-variable {
  border: none;
  background: none;
  color: ${({ colors }) => colors.blueAccent[100]}
}

@media(max-width: 700px) {
  width: 20rem;
}
`;
