import { ProductModel } from '../@types/models';
import { Api } from './api';
import { toProduct } from '../utils/models-utils';

const CONTROLLER = 'Produto';

export const list = async (): Promise<ProductModel[]> => {
  return await Api.get(CONTROLLER).then((res: any) => {
    return res.data.map((x: any) => toProduct(x));
  });
};

export const getById = async (id: string): Promise<ProductModel> => {
  return await Api.get(`${CONTROLLER}/${id}`).then((res: any) =>
    toProduct(res),
  );
};

export const create = async (params: {
  name: string;
  signature?: string;
}): Promise<string> => {
  const response = await Api.post(CONTROLLER, {
    nome: params.name,
    assinaturaId: params.signature,
  });
  return response.data;
};

export const update = async (model: ProductModel): Promise<boolean> => {
  return await Api.put(CONTROLLER, {
    produtoId: model.id,
    nome: model.description,
  }).then((res) => {
    console.log(res);
    return true;
  });
};

export const remove = async (id: string): Promise<boolean> => {
  return await Api.delete(`${CONTROLLER}/${id}`).then((res) => {
    console.log(res);
    return true;
  });
};
