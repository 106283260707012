import { UserModel } from '../@types/models';
import { Api } from './api';

export const loginRequest = async (email: string, password: string) => {
  try {
    const request = await Api.post('Usuario/login', { email, senha: password });
    return request.data;
  } catch (error) {
    return null;
  }
};

export const getUserAuth = async (): Promise<UserModel | null> => {
  try {
    return await Api.get('Usuario').then(({ data }) => {
      return {
        id: data[0].id,
        email: data[0].email,
        name: data[0].nome,
        phone: data[0].celular,
        userType: data[0].tipo,
      } as UserModel;
    });
  } catch (error) {
    return null;
  }
};
