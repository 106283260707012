import { createContext, useEffect, useState } from 'react';
import { IAuthProvider, IUserAuth, UserContextType } from '../../@types/auth';
import { getUserLocalStorage, setUserlocalStorage } from './utils';

import * as AuthService from '../../services/auth.service';
import { UserModel } from '../../@types/models';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext<UserContextType>(
  {} as UserContextType,
);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<IUserAuth | null>();

  useEffect(() => {
    const user = getUserLocalStorage();
    if (user) setUser(user);
  }, []);

  const authenticate = async (email: string, password: string) => {
    const response = await AuthService.loginRequest(email, password);

    if (response) {
      const payload: IUserAuth = { token: response, email };
      const decoded: any = jwt_decode(payload.token || '');

      if (decoded) {
        payload.data = {
          id: decoded.nameid,
          email: email,
          name: decoded.unique_name, // esperando atualizaçaõ dos dados do token
          userType: decoded.TipoUsuario,
          signature: decoded.AssinautraId,
        } as UserModel;
        payload.expire = new Date(decoded.exp);
      }

      setUserlocalStorage(payload);
      setUser(payload);
    }
  };

  const logout = () => {
    setUser(null);
    setUserlocalStorage(null);
  };

  return (
    <AuthContext.Provider value={{ ...user, authenticate, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
