import { Box, Button, Drawer, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import SearchInputBox from '../../components/SearchInputBox';
import { tokens } from '../../theme';
import i18n from '../../translate/i18n';
import { ProductsContainer } from './styles';

import * as ProductService from '../../services/product.service';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { ProductModel } from '../../@types/models';
import ProductsGrid from './ProductsGrid';
import { ProductForm } from './ProductForm';
import { useGlobalState } from '../../hooks/useGlobalState';
import { ConfirmAction } from '../../components/CofirmAction';

export const Products = () => {
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [actionForm, setActionForm] = useState<'new' | 'edit' | 'show'>('new');
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductModel>(
    {} as ProductModel,
  );

  const {
    showSuccessAlert,
    showErrorAlert,
    showLoading,
    hideLoading,
    handleTitleSectionLabels,
    handleResponseError,
  } = useGlobalState();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    handleTitleSectionLabels(
      i18n.t('pages.products.title'),
      i18n.t('pages.products.subtitle'),
    );
  }, [handleTitleSectionLabels]);

  const handleLoadProducts = useCallback(async () => {
    await ProductService.list()
      .then((res) => {
        setProducts(res);
      })
      .catch((error) => {
        handleResponseError(error);
      });
  }, [handleResponseError]);

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const handleSelectProduct = (id: string, action: 'edit' | 'show') => {
    const selProduct = filteredProducts.find((x) => x.id === id);
    setActionForm(action);
    setSelectedProduct(selProduct || ({} as ProductModel));
    handleActionForm(true);
  };

  const handleRemoveProduct = async (id: string) => {
    const selectedItem = filteredProducts.find((x) => x.id === id);
    if (selectedItem) {
      setSelectedProduct(selectedItem);
      setShowConfirm(true);
    }
  };

  const removeProduct = async (id: string) => {
    showLoading();
    try {
      await ProductService.remove(id)
        .then((res) => {
          if (res) {
            showSuccessAlert(i18n.t('pages.products.alerts.delete.success'));
            handleLoadProducts();
            hideLoading();
          }
        })
        .catch((error) => {
          handleResponseError(error);
          hideLoading();
        });
    } catch (error: any) {
      showErrorAlert(error.message);
      hideLoading();
    }
  };

  const handleConfirmAction = () => {
    setShowConfirm(false);
    setSelectedProduct({} as ProductModel);
  };

  const toggleFormDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        console.log(event);
      }
      handleActionForm(open);
    };

  useEffect(() => {
    handleLoadProducts();
  }, [handleLoadProducts]);

  const handleActionForm = (open: boolean) => {
    if (!open) {
      setSelectedProduct({} as ProductModel);
      setActionForm('new');
    }
    setShowForm(open);
  };

  const handleCloseForm = (option: 'cancel' | 'save' | 'edit') => {
    if (option === 'save' || option === 'edit') {
      handleLoadProducts();
    }
    handleActionForm(false);
  };

  const filteredProducts = searchTerm
    ? products.filter((p) =>
        p.description.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : products;

  return (
    <ProductsContainer colors={colors}>
      <Box className="content">
        <Box className="controlBar">
          <SearchInputBox
            handleChange={handleSearchChange}
            width={350}
            placeholder={i18n.t('pages.products.search') ?? ''}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Inventory2OutlinedIcon />}
            onClick={() => handleActionForm(true)}
          >
            {i18n.t('pages.products.new')}
          </Button>
        </Box>

        <ProductsGrid
          products={filteredProducts}
          handleSelectedProduct={handleSelectProduct}
          handleRemoveProduct={handleRemoveProduct}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showForm}
        onClose={toggleFormDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <ProductForm
          product={selectedProduct}
          action={actionForm}
          closeForm={handleCloseForm}
        />
      </Drawer>
      <ConfirmAction
        open={showConfirm}
        title={i18n.t('confirmAction.title')}
        body={i18n.t('confirmAction.delete', { resource: 'do produto' })}
        closeAction={() => handleConfirmAction()}
        handleConfirm={async () => {
          await removeProduct(selectedProduct.id);
          handleConfirmAction();
        }}
      />
    </ProductsContainer>
  );
};
