import { IconButton, useTheme } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../theme';

import { SearchInputContainer } from './styles';

export interface SearchInputProps {
  handleChange: (value: string) => void;
  width?: number;
  placeholder?: string;
}

const SearchInputBox = ({
  handleChange,
  width,
  placeholder,
}: SearchInputProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <SearchInputContainer width={width ?? 300} background={colors.primary[400]}>
      <InputBase
        onChange={(e) => handleChange(e.target.value)}
        sx={{ ml: 2, flex: 1 }}
        placeholder={placeholder ?? ''}
      />
      <IconButton type="button" sx={{ p: 1 }}>
        <SearchIcon />
      </IconButton>
    </SearchInputContainer>
  );
};

export default SearchInputBox;
