import { ContactModel } from '../../@types/models';
import { DefaultGrid } from '../../components/DefaultGrid';
import { ActionButton } from '../../components/DefaultGrid/ActionButton';

import i18n from '../../translate/i18n';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { formatPhoneNumber } from '../../utils/utils';

export interface ContactsGridProps {
  contacts: Array<ContactModel>;
  handleSelectedContact: (id: string, action: 'edit' | 'show') => void;
}

const ContactsGrid = ({
  contacts,
  handleSelectedContact,
}: ContactsGridProps) => {
  const editContact = (item: any) => {
    handleSelectedContact(item.id, 'edit');
  };

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('pages.contacts.datagrid.columns.name'),
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'phone',
      headerName: i18n.t('pages.contacts.datagrid.columns.phone'),
      flex: 1,
      cellClassName: 'name-column--cell',
      renderCell: (params: any) => {
        return formatPhoneNumber(params.value);
      },
    },
    {
      field: 'email',
      headerName: i18n.t('pages.contacts.datagrid.columns.email'),
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <ActionButton
            params={params}
            action={editContact}
            icon={<EditOutlinedIcon fontSize="inherit" />}
            tooltipText={i18n.t('datagrid.actionButtons.edit')}
            key={`details--${params.id}`}
          />
        );
      },
    },
  ];

  return <DefaultGrid rows={contacts || []} columns={columns} />;
};

export default ContactsGrid;
