import { ProductModel } from '../../@types/models';
import { DefaultGrid } from '../../components/DefaultGrid';

import { ActionButtonsColumn } from '../../components/DefaultGrid/ActionButtonsColumn';
// import { ActionButton } from '../../components/DefaultGrid/ActionButton';
// import { useAuth } from '../../hooks/useAuth';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import i18n from '../../translate/i18n';

export interface ProductsGridProps {
  products: Array<ProductModel>;
  handleSelectedProduct: (id: string, action: 'edit' | 'show') => void;
  handleRemoveProduct: (id: string) => void;
}

const ProductsGrid = ({
  products,
  handleSelectedProduct,
  handleRemoveProduct,
}: ProductsGridProps) => {
  // const { data } = useAuth();
  // const showProduct = (item: any) => {
  //   handleSelectedProduct(item.id, 'show');
  // };

  const editProduct = (item: any) => {
    handleSelectedProduct(item.id, 'edit');
  };

  const deleteProduct = async (item: any) => {
    handleRemoveProduct(item.id);
  };

  const columns = [
    {
      field: 'description',
      headerName: i18n.t('pages.products.datagrid.columns.description'),
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <ActionButtonsColumn
            params={params}
            actionDelete={deleteProduct}
            actionEdit={editProduct}
          />
          // <>
          //   {data?.userType === 'AdminAssinatura' ||
          //   data?.userType === 'AdminGeral' ? (
          //     <ActionButtonsColumn
          //       params={params}
          //       actionDelete={deleteProduct}
          //       actionEdit={editProduct}
          //     />
          //   ) : (
          //     <ActionButton
          //       params={params}
          //       action={showProduct}
          //       icon={<VisibilityOutlinedIcon fontSize="inherit" />}
          //       tooltipText={i18n.t(
          //         'pages.products.datagrid.columns.buttons.details',
          //       )}
          //       key={`details--${params.id}`}
          //     />
          //   )}
          // </>
        );
      },
    },
  ];

  return <DefaultGrid rows={products || []} columns={columns} />;
};

export default ProductsGrid;
