import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ActionButtonsContainer } from './styles';
import { ActionButton } from './ActionButton';
import { useState } from 'react';
import i18n from '../../translate/i18n';

export interface ActionButtonsCollumnProps {
  params: any;
  actionEdit: (model: any) => void;
  actionDelete: (model: any) => void;
  otherButtons?: IButtonData[];
}

export interface IButtonData {
  params: any;
  tooltipText: string;
  action: (model: any) => void;
  icon: any;
}

export const ActionButtonsColumn = ({
  params,
  actionEdit,
  actionDelete,
  otherButtons = [],
}: ActionButtonsCollumnProps) => {
  const [buttons] = useState<IButtonData[]>([
    {
      params: params,
      tooltipText: i18n.t('datagrid.actionButtons.edit'),
      action: actionEdit,
      icon: <EditOutlinedIcon />,
    },
    {
      params: params,
      tooltipText: i18n.t('datagrid.actionButtons.delete'),
      action: actionDelete,
      icon: <DeleteForeverOutlinedIcon />,
    },
    ...otherButtons,
  ]);

  return (
    <ActionButtonsContainer>
      {buttons.map((button) => (
        <ActionButton
          params={button.params}
          action={button.action}
          icon={button.icon}
          tooltipText={button.tooltipText}
          key={`${button.tooltipText}--${button.params.id}`}
        />
      ))}
    </ActionButtonsContainer>
  );
};
