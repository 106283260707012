import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { MenuItem } from 'react-pro-sidebar';

export interface SidebarContainerProps {
  background: string;
}

export const Container = styled(Box)<SidebarContainerProps>`
  height: 100%;
  min-height: 100vh;

  & .pro-sidebar-inner {
    background: ${({ background }) => background} !important;
  }

  & .pro-icon-wrapper {
    background-color: transparent !important;
  }

  & .pro-inner-item {
    padding: 5px 35px 5px 20px !important;
  }

  & .pro-inner-item:hover {
    color: #868dfb !important;
  }

  & .pro-menu-item.active {
    color: #6870fa !important;
  }
`;

export interface HeaderLogoStyleProps {
  color: string;
}

export const HeaderLogoContainer = styled(MenuItem)`
  margin: "10px 0 20px 0";
  color: ${({ color }) => color};

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
  }
`;

export const SectionTitleContainer = styled(Typography)`
  margin: "15px 0 5px 20px";
`;
