import { Box, Button } from '@mui/material';
import { MessageQueueModel } from '../../@types/models';
import SectionTitle from '../../components/SectionTitle';
import i18n from '../../translate/i18n';
import { MessageQueueDetailsContainer } from './styles';

export const MessageQueueDetails = ({
  messageQueue,
  closeForm,
}: {
  messageQueue: MessageQueueModel;
  closeForm: () => void;
}) => {
  console.log(messageQueue);
  return (
    <MessageQueueDetailsContainer>
      <Box className="content" m={2}>
        <SectionTitle title={i18n.t('pages.messageQueues.details.title')} />
        <Box display="flex" justifyContent="end" mt="20px" gap={2}>
          <Button
            type="reset"
            color="info"
            variant="contained"
            onClick={() => closeForm()}
          >
            {i18n.t('pages.messageQueues.details.buttons.cancel')}
          </Button>
        </Box>
      </Box>
    </MessageQueueDetailsContainer>
  );
};
