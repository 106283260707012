import { MessageTemplateModel, MessageVariable } from '../@types/models';
import { toMessageTemplate } from '../utils/models-utils';
import { Api } from './api';

const CONTROLLER = 'Template';

export const getVariables = async (): Promise<MessageVariable[]> => {
  return await Api.get('Mensagem/variaveis').then((res) => {
    return Object.entries(res.data).map(([key, value]) => {
      return { name: key, value } as MessageVariable;
    });
  });
};

export const list = async (): Promise<MessageTemplateModel[]> => {
  return await Api.get(`${CONTROLLER}`).then((res) => {
    return res.data.map((x: any) => toMessageTemplate(x));
  });
};

export const getById = async (id: string): Promise<MessageTemplateModel> => {
  return await Api.get(`${CONTROLLER}/${id}`).then((res) => {
    return toMessageTemplate(res.data);
  });
};

export const create = async (
  name: string,
  text: string,
  delay: number,
): Promise<string> => {
  return await Api.post(`${CONTROLLER}`, {
    nome: name,
    texto: text,
    segundosDelay: delay,
  }).then((res) => res.data);
};

export const update = async (model: MessageTemplateModel): Promise<boolean> => {
  return await Api.put(CONTROLLER, {
    templateMensagemId: model.id,
    nome: model.name,
    texto: model.body,
    segundosDelay: model.delay,
  }).then(() => {
    return true;
  });
};

export const remove = async (id: string): Promise<boolean> => {
  return await Api.delete(`${CONTROLLER}/${id}`).then(() => {
    return true;
  });
};
