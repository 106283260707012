import { AxiosError } from 'axios';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IGlobalContextType } from '../../@types/layouts';
import { useAuth } from '../../hooks/useAuth';
import i18n from '../../translate/i18n';

export const GlobalContext = createContext({} as IGlobalContextType);

export const GlobalStateProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  // Title
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleTitleSectionLabels = (title: string, subTitle: string) => {
    setTitle(title);
    setSubTitle(subTitle);
  };

  // loading
  const [toggleLoading, setToggleLoading] = useState(false);

  const showLoading = () => {
    if (!toggleLoading) setToggleLoading(true);
  };
  const hideLoading = () => setToggleLoading(false);

  // alert;
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState<
    'error' | 'warning' | 'info' | 'success'
  >('info');
  const [alertMessage, setAlertMessage] = useState('');

  const closeAlert = () => {
    setAlertMessage('');
    setShowAlert(false);
  };

  const showSuccessAlert = (message: string, delay?: number) => {
    setAlertMessage(message);
    setTypeAlert('success');
    setShowAlert(true);
    setTimeout(closeAlert, delay || 5000);
  };

  const showErrorAlert = (message: string, delay?: number) => {
    setAlertMessage(message);
    setTypeAlert('error');
    setShowAlert(true);
    setTimeout(closeAlert, delay || 5000);
  };

  const handleResponseError = (error: AxiosError): string => {
    if (error.response?.status === 401) {
      navigate('/login');
      logout();
      return i18n.t('response.errors.error401');
    }

    if (error.response?.status === 403) {
      // showErrorAlert(i18n.t('response.errors.error403'));
      // logout();
      return i18n.t('response.errors.error403');
    }

    if (error.response?.status === 400 && error.response?.data) {
      const errors = (error.response?.data as any).errors;
      if (errors) {
        return errors[0].message;
      }

      const detail = (error.response?.data as any).detail;
      if (detail) return detail;
    }

    return error.message;
  };

  return (
    <GlobalContext.Provider
      value={{
        title,
        subTitle,
        showAlert,
        typeAlert,
        alertMessage,
        toggleLoading,
        handleTitleSectionLabels,
        showSuccessAlert,
        showErrorAlert,
        closeAlert,
        showLoading,
        hideLoading,
        handleResponseError,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
