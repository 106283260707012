import axios from 'axios';
import { getUserLocalStorage } from '../context/AuthProvider/utils';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,OPTIONS,PATCH,DELETE,POST,PUT',
    'Access-Control-Allow-Headers':
      'X-CSRF-Token, X-Requested-With, Access-Control-Request-Method, Access-Control-Request-Headers Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Authorization',
  },
});

Api.interceptors.request.use(
  (config) => {
    const user = getUserLocalStorage();
    config.headers.Authorization = 'Bearer ' + user?.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const Api2 = axios.create({
  baseURL: 'https://dummyjson.com/',
});

Api2.interceptors.request.use(
  (config) => {
    const user = getUserLocalStorage();
    config.headers.Authorization = user?.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
