import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import i18n from '../../translate/i18n';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface Options {
  label: string;
  id: string;
}

export interface ChoiceContactsProps {
  options: Options[];
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T,
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

export const ChoiceContacts = ({
  options,
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  handleChange,
}: ChoiceContactsProps) => {
  return (
    <Autocomplete
      value={values.contacts}
      onChange={(event: any, newValue: Options[]) => {
        setFieldValue('contacts', newValue);
        handleChange(event);
      }}
      multiple
      id="contacts"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      sx={{ gridColumn: 'span 4' }}
      renderInput={(params) => (
        <TextField
          name="contacts"
          variant="filled"
          error={!!touched.message && !!errors.message}
          helperText={touched.message && errors.message}
          onBlur={handleBlur}
          {...params}
          label={i18n.t('pages.messages.form.fields.contacts')}
        />
      )}
      noOptionsText={i18n.t('pages.messages.form.labels.noOptionsText')}
    />
  );
};
