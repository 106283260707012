import { Box, Button, Drawer, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { MessageTemplateModel } from '../../@types/models';
import { tokens } from '../../theme';
import { MessageTemplatesContainer } from './styles';
import i18n from '../../translate/i18n';

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { useGlobalState } from '../../hooks/useGlobalState';
import SearchInputBox from '../../components/SearchInputBox';

import * as MessageTemplateService from '../../services/message-template.service';

import { MessageTemplatesGrid } from './MessageTemplatesGrid';
import { MessageTemplateForm } from './MessageTemplateForm';
import { ConfirmAction } from '../../components/CofirmAction';

export const MessageTemplates = () => {
  const [messages, setMessages] = useState<MessageTemplateModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionForm, setActionForm] = useState<'new' | 'edit'>('new');
  const [selectedMessage, setSelectedMessage] = useState<MessageTemplateModel>(
    {} as MessageTemplateModel,
  );

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    showSuccessAlert,
    showErrorAlert,
    showLoading,
    hideLoading,
    handleTitleSectionLabels,
    handleResponseError,
  } = useGlobalState();

  useEffect(() => {
    handleTitleSectionLabels(
      i18n.t('pages.messageTemplates.title'),
      i18n.t('pages.messageTemplates.subtitle'),
    );
  }, [handleTitleSectionLabels]);

  const handleLoadMessages = useCallback(async () => {
    await MessageTemplateService.list()
      .then((itens) => {
        setMessages(itens);
      })
      .catch((err) => {
        handleResponseError(err);
      });
  }, [handleResponseError]);

  const handleLoadTemplate = useCallback(
    async (id: string) => {
      await MessageTemplateService.getById(id)
        .then((data) =>
          setSelectedMessage(data || ({} as MessageTemplateModel)),
        )
        .catch((err) => {
          const msg = handleResponseError(err);
          showErrorAlert(
            msg || i18n.t('pages.messageTemplates.alerts.loading.error'),
          );
        });
    },
    [handleResponseError, showErrorAlert],
  );

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const handleSelectMessage = async (id: string) => {
    showLoading();
    await handleLoadTemplate(id);
    setActionForm('edit');
    hideLoading();
    handleActionForm(true);
  };

  const handleRemoveTemplate = async (id: string) => {
    const selectedItem = filteredMessages.find((x) => x.id === id);
    if (selectedItem) {
      setSelectedMessage(selectedItem);
      setShowConfirm(true);
    }
  };

  const handleConfirmAction = () => {
    setShowConfirm(false);
    setSelectedMessage({} as MessageTemplateModel);
  };

  const removeTemplate = async (id: string) => {
    showLoading();
    try {
      await MessageTemplateService.remove(id)
        .then((res) => {
          if (res) {
            showSuccessAlert(
              i18n.t('pages.messageTemplates.alerts.delete.success'),
            );
            handleLoadMessages();
            hideLoading();
          }
        })
        .catch((error) => {
          handleResponseError(error);
          hideLoading();
        });
    } catch (error: any) {
      showErrorAlert(error.message);
      hideLoading();
    }
  };

  const toggleFormDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        console.log(event);
      }
      handleActionForm(open);
    };

  useEffect(() => {
    handleLoadMessages();
  }, [handleLoadMessages]);

  const handleActionForm = (open: boolean) => {
    if (!open) {
      setSelectedMessage({} as MessageTemplateModel);
      setActionForm('new');
    }
    setShowForm(open);
  };

  const handleCloseForm = (option: 'cancel' | 'save' | 'edit') => {
    if (option === 'save' || option === 'edit') {
      handleLoadMessages();
    }
    handleActionForm(false);
  };

  const filteredMessages = searchTerm
    ? messages.filter(
        (m) =>
          m.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          m.body.toString().toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : messages;

  return (
    <MessageTemplatesContainer colors={colors}>
      <Box className="content">
        <Box className="controlBar">
          <SearchInputBox
            handleChange={handleSearchChange}
            width={350}
            placeholder={i18n.t('pages.messageTemplates.search') ?? ''}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<MessageOutlinedIcon />}
            onClick={toggleFormDrawer(true)}
          >
            {i18n.t('pages.messageTemplates.new')}
          </Button>
        </Box>

        <MessageTemplatesGrid
          messages={filteredMessages}
          handleSelectedMessage={handleSelectMessage}
          handleRemoveMessage={handleRemoveTemplate}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showForm}
        onClose={toggleFormDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <MessageTemplateForm
          message={selectedMessage}
          action={actionForm}
          closeForm={handleCloseForm}
        />
      </Drawer>
      <ConfirmAction
        open={showConfirm}
        title={i18n.t('confirmAction.title')}
        body={i18n.t('confirmAction.delete', { resource: 'do template' })}
        closeAction={() => handleConfirmAction()}
        handleConfirm={async () => {
          await removeTemplate(selectedMessage.id);
          handleConfirmAction();
        }}
      />
    </MessageTemplatesContainer>
  );
};
