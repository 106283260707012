import { Alert, Snackbar } from '@mui/material';
import { useGlobalState } from '../../hooks/useGlobalState';

export const AlertBox = () => {
  const { showAlert, typeAlert, alertMessage, closeAlert } = useGlobalState();

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') return;
    closeAlert;
  };

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={5000}
      onClose={handleCloseAlert}
    >
      <Alert
        onClose={handleCloseAlert}
        severity={typeAlert}
        sx={{ width: '100%' }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};
