import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { tokens } from '../../theme';
import { AccountCardMenuContent } from './styles';

import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import i18n from '../../translate/i18n';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../hooks/useGlobalState';

export interface AccountCardMenuProps {
  openLink: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

export const AccountCardMenu = ({
  openLink,
  anchorEl,
  handleClose,
}: AccountCardMenuProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const userAuth = useAuth();

  const { logout } = useAuth();
  const { showLoading, hideLoading } = useGlobalState();

  const getGreetings = (): string => {
    const date = new Date();
    const hours = date.getHours();
    return hours < 12
      ? i18n.t('topbarMenu.account.greetings.morning')
      : hours <= 18 && hours >= 12
      ? i18n.t('topbarMenu.account.greetings.afternoon')
      : i18n.t('topbarMenu.account.greetings.evening');
  };

  const getUserType = (): string => {
    switch (userAuth.data?.userType) {
      case 'AdminAssinatura':
        return i18n.t('topbarMenu.account.userType.AdminAssinatura');
      case 'AdminGeral':
        return i18n.t('topbarMenu.account.userType.AdminGeral');
      case 'SemCelular':
        return i18n.t('topbarMenu.account.userType.SemCelular');
      case 'ComCelular':
        return i18n.t('topbarMenu.account.userType.ComCelular');
      default:
        return '';
    }
  };

  const handleClick = () => {
    showLoading();
    setTimeout(() => {
      handleClose();
      logout();
      hideLoading();
    }, 1000);
  };

  return (
    <AccountCardMenuContent
      colors={colors}
      id="account-card"
      aria-labelledby="account-card"
      anchorEl={anchorEl}
      open={openLink}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem>
        <Box className="greetings">
          <Typography component="h5" variant="h5">
            <strong>{getGreetings()},</strong> {userAuth.data?.name}
          </Typography>
          <Typography component="h6" variant="h6">
            {getUserType()}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleClick}>
        <ListItemText>{i18n.t('topbarMenu.account.logout')}</ListItemText>
        <ListItemIcon>
          <ExitToAppOutlinedIcon fontSize="small" />
        </ListItemIcon>
      </MenuItem>
    </AccountCardMenuContent>
  );
};
