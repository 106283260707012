import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import LoginForm from '../../components/LoginForm';
import { useAuth } from '../../hooks/useAuth';
import { tokens } from '../../theme';
import { SignContainer } from './styles';

const SignIn = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const navigate = useNavigate();

  if (auth.email) navigate('/dashboard');

  return (
    <SignContainer colors={colors} theme={theme}>
      <Box className="banner-bg"></Box>
      <Box className="content">
        <Box className="card-login">
          <LoginForm />
        </Box>
      </Box>
    </SignContainer>
  );
};

export default SignIn;
