import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { useAuth } from '../../hooks/useAuth';
import { LoginContainer } from './styles';
import i18n from '../../translate/i18n';
import Header from './Header';

const LoginForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (ev: any) => ev.preventDefault;
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') return;
    setShowAlert(false);
  };

  const handleLoading = () => setShowLoading(false);

  return (
    <LoginContainer>
      <Box className="content">
        <Header />
        <Formik
          initialValues={{
            email: '',
            password: '',
            submit: null,
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setShowLoading(true);
            try {
              await auth.authenticate(values.email, values.password);

              setStatus({ success: true });
              setSubmitting(false);
              handleLoading();
              navigate('/dashboard');
            } catch (err: any) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              handleLoading();
              setShowAlert(true);
            }
          }}
          validationSchema={checkoutSchema}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.login.email')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type={showPassword ? 'text' : 'password'}
                  label={i18n.t('pages.login.password')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: 'span 4' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Mostrar/Ocultar senha"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Link
                  variant="h6"
                  component={RouterLink}
                  to=""
                  color="text.primary"
                  width={400}
                >
                  {i18n.t('pages.login.forgot_password')}
                </Link>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                mt="20px"
                sx={{ gridColumn: 'span 4' }}
              >
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {i18n.t('pages.login.button')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: '100%' }}
        >
          {i18n.t('pages.login.messages.error')}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        onClick={handleLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoginContainer>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('validators.errors.email') ?? '')
    .required(i18n.t('validators.errors.required') ?? ''),
  password: yup
    .string()
    .max(255)
    .required(i18n.t('validators.errors.required') ?? ''),
});

export default LoginForm;
