import { useContext } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { IconBoxContainer } from './styles';

// Icons imports
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { ColorModeContext } from '../../theme';
import i18n from '../../translate/i18n';
import { IconButtonAccount } from './IconButtonAccount';

const IconsBox = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <IconBoxContainer>
      <Tooltip
        title={
          theme.palette.mode === 'dark'
            ? i18n.t('topbarMenu.iconDark')
            : i18n.t('topbarMenu.iconLight')
        }
        arrow
      >
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Tooltip>
      {/* <IconButton>
        <NotificationsOutlinedIcon />
      </IconButton> */}
      {/* <IconButton>
        <SettingsOutlinedIcon />
      </IconButton> */}
      <IconButtonAccount />
    </IconBoxContainer>
  );
};

export default IconsBox;
