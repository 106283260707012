import { TextField } from '@mui/material';
import { useState } from 'react';
import { MessageVariable } from '../../@types/models';
import i18n from '../../translate/i18n';
import { MessageBuilderContainer } from './styles';
import { VariableSelectMenu } from './VariableSelectMenu';

export interface MessageBuilderProps {
  variables: MessageVariable[];
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T,
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

export const MessageBuilder = ({
  variables,
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  handleChange,
}: MessageBuilderProps) => {
  const [lastCursorPos, setLastCursorPos] = useState<number | null>(null);

  const handleAddSpecialContentToMessage = (
    item: string,
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const text: string = values.message;
    const before = text.substring(0, lastCursorPos || 0);
    const after = text.substring(lastCursorPos || 0);

    setFieldValue('message', `${before} ${item} ${after}`);
  };

  return (
    <MessageBuilderContainer sx={{ gridColumn: 'span 4' }}>
      <TextField
        fullWidth
        multiline
        variant="filled"
        type="text"
        label={i18n.t('pages.messageTemplates.form.fields.message')}
        onBlur={(e) => {
          handleBlur(e);
          setLastCursorPos(e.target.selectionStart);
        }}
        onChange={handleChange}
        value={values.message}
        name="message"
        error={!!touched.message && !!errors.message}
        helperText={touched.message && errors.message}
        sx={{ gridColumn: 'span 4' }}
        rows={4}
      />

      <VariableSelectMenu
        variables={variables}
        handleSelectedVariable={(variable: MessageVariable) => {
          handleAddSpecialContentToMessage(
            variable.value,
            values,
            setFieldValue,
          );
        }}
      />
    </MessageBuilderContainer>
  );
};
