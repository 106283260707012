import { Box, Button, MenuItem, TextField, useMediaQuery } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import i18n from '../../translate/i18n';
import { UserFormContainer } from './styles';
import { UserModel } from '../../@types/models';
import * as yup from 'yup';
import { Formik } from 'formik';

import * as UserService from '../../services/users.service';

import { useGlobalState } from '../../hooks/useGlobalState';
import { InputMask } from '../../components/InputMask';

const UserForm = ({
  user,
  action,
  closeForm,
}: {
  user: UserModel;
  action: 'new' | 'edit' | 'show';
  closeForm: (option: 'cancel' | 'save' | 'edit') => void;
}) => {
  const {
    showLoading,
    hideLoading,
    showErrorAlert,
    showSuccessAlert,
    handleResponseError,
  } = useGlobalState();

  const isNonMobile = useMediaQuery('(min-width:600px)');

  return (
    <UserFormContainer>
      <Box className="content" m={2}>
        <SectionTitle
          title={
            action === 'new'
              ? i18n.t('pages.users.form.title.new')
              : i18n.t('pages.users.form.title.edit')
          }
        />
        <Formik
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            showLoading();

            const handleSuccess = (message: string) => {
              setStatus({ success: true });
              setSubmitting(false);
              hideLoading();
              showSuccessAlert(message);
            };

            const handleError = (err: any, message: string) => {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              hideLoading();
              const msg = handleResponseError(err);
              showErrorAlert(msg || message);
            };

            try {
              user.name = values.name;
              user.email = values.email;
              user.phone = values.phone.replace(/[^0-9]/g, '');
              user.userType = values.userType;
              if (values.password) user.password = values.password;

              if (action === 'new') {
                await UserService.create(user)
                  .then((res) => {
                    console.log(res);
                    handleSuccess(i18n.t('pages.users.alerts.save.success'));
                  })
                  .catch((err) =>
                    handleError(err, i18n.t('pages.users.alerts.save.error')),
                  );
              } else {
                await UserService.update(user)
                  .then((res) => {
                    console.log(res);
                    handleSuccess(i18n.t('pages.users.alerts.edit.success'));
                  })
                  .catch((err) =>
                    handleError(err, i18n.t('pages.users.alerts.edit.error')),
                  );
              }

              closeForm(action === 'new' ? 'save' : 'edit');
            } catch (err: any) {
              handleError(
                err,
                action === 'new'
                  ? i18n.t('pages.users.alerts.save.error')
                  : i18n.t('pages.users.alerts.edit.error'),
              );
            }
          }}
          initialValues={{
            name: user.name,
            phone: user.phone,
            email: user.email,
            userType: user.userType,
            password: '',
            submit: null,
          }}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.users.form.name')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name || ''}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.users.form.phone')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone || ''}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: 'span 2' }}
                  InputProps={{
                    inputComponent: InputMask as any,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.users.form.email')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ''}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  select
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.users.form.userType.label')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.userType || ''}
                  name="userType"
                  error={!!touched.userType && !!errors.userType}
                  helperText={touched.userType && errors.userType}
                  sx={{ gridColumn: 'span 2' }}
                >
                  {userTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label={i18n.t('pages.users.form.password')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ''}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: 'span 4' }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px" gap={2}>
                <Button type="reset" color="info" variant="contained">
                  {i18n.t('pages.users.form.buttons.cancel')}
                </Button>
                {action !== 'show' && (
                  <Button type="submit" color="secondary" variant="contained">
                    {action === 'new'
                      ? i18n.t('pages.users.form.buttons.new')
                      : i18n.t('pages.users.form.buttons.edit')}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </UserFormContainer>
  );
};

const phoneRegExp =
  /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;

const checkoutSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('validators.errors.required') ?? '')
    .max(150, i18n.t('validators.errors.max', { max: 150 }) ?? ''),
  email: yup
    .string()
    .email(i18n.t('validators.errors.email') ?? '')
    .required(i18n.t('validators.errors.required') ?? '')
    .max(150, i18n.t('validators.errors.max', { max: 150 }) ?? ''),
  phone: yup
    .string()
    .matches(phoneRegExp, i18n.t('validators.errors.phone') ?? '')
    .required(i18n.t('validators.errors.required') ?? ''),
  password: yup
    .string()
    .required(i18n.t('validators.errors.required') ?? '')
    .min(8, i18n.t('validators.errors.max', { min: 8 }) ?? '')
    .max(12, i18n.t('validators.errors.max', { max: 12 }) ?? ''),
});

const userTypes = [
  {
    value: 'AdminAssinatura',
    label: i18n.t('pages.users.form.userType.types.AdminAssinatura'),
  },
  {
    value: 'AdminGeral',
    label: i18n.t('pages.users.form.userType.types.AdminGeral'),
  },
  {
    value: 'SemCelular',
    label: i18n.t('pages.users.form.userType.types.SemCelular'),
  },
  {
    value: 'ComCelular',
    label: i18n.t('pages.users.form.userType.types.ComCelular'),
  },
];

export default UserForm;
