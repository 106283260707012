import { Box } from '@mui/material';
import styled from 'styled-components';

export const LoginContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
