import { ContactModel } from '../@types/models';
import { toContact } from '../utils/models-utils';
import { Api } from './api';

const CONTROLLER = 'Usuario';

export const list = async (): Promise<ContactModel[]> => {
  return await Api.get(`${CONTROLLER}/contatos`).then((res) => {
    return res.data.map((x: any) => toContact(x));
  });
};

export const save = async (model: ContactModel) => {
  return await Api.put(`${CONTROLLER}/contatos`, {
    nome: model.name,
    celular: model.phone,
    email: model.email,
  });
};
