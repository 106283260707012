import { IUserAuth } from '../../@types/auth';

const __KEY__ = '__-=u@a=-__';

export const setUserlocalStorage = (user: IUserAuth | null) => {
  localStorage.setItem(__KEY__, JSON.stringify(user));
};

export const getUserLocalStorage = () => {
  const json = localStorage.getItem(__KEY__);
  if (!json) return null;
  return JSON.parse(json) ?? null;
};
