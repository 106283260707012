import { IconButton, Tooltip } from '@mui/material';

export interface ActionButtonProps {
  params: any;
  tooltipText: string;
  action: (data: any) => void;
  icon: any;
  key: string;
}

export const ActionButton = ({
  params,
  tooltipText,
  icon,
  action,
}: ActionButtonProps) => {
  return (
    <Tooltip title={tooltipText}>
      <IconButton aria-label={tooltipText} onClick={() => action(params.row)}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};
