import { toUser } from '../utils/models-utils';
import { UserModel } from '../@types/models';
import { Api } from './api';

const CONTROLLER = 'Usuario';

export const list = async (): Promise<UserModel[]> => {
  return await Api.get(CONTROLLER).then(({ data }) => {
    return data.map((x: any) => toUser(x));
  });
};

export const getById = async (id: string): Promise<UserModel> => {
  return await Api.get(`${CONTROLLER}/${id}`).then(({ data }) => toUser(data));
};

export const create = async (params: UserModel): Promise<string> => {
  const response = await Api.post(CONTROLLER, {
    nome: params.name,
    email: params.email,
    celular: params.phone,
    tipoUsuario: params.userType,
    senha: params.password,
  });
  return response.data;
};

export const update = async (model: UserModel): Promise<boolean> => {
  return await Api.put(CONTROLLER, {
    produtoId: model.id,
    nome: model.name,
    celular: model.phone,
    tipoUsuario: model.userType,
  }).then((res) => {
    console.log(res);
    return true;
  });
};

export const remove = async (id: string): Promise<boolean> => {
  return await Api.delete(`${CONTROLLER}/${id}`).then((res) => {
    console.log(res);
    return true;
  });
};
