import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { ContactModel } from '../../@types/models';
import { ContactFormContainer } from './styles';
import * as yup from 'yup';
import i18n from '../../translate/i18n';
import { Button, TextField, useMediaQuery } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import { InputMask } from '../../components/InputMask';

import * as ContactService from '../../services/contact.service';
import { useGlobalState } from '../../hooks/useGlobalState';

export const ContactForm = ({
  contact,
  action,
  closeForm,
}: {
  contact: ContactModel;
  action: 'new' | 'edit' | 'show';
  closeForm: (option: 'cancel' | 'save') => void;
}) => {
  const {
    showLoading,
    hideLoading,
    showErrorAlert,
    showSuccessAlert,
    handleResponseError,
  } = useGlobalState();
  const isNonMobile = useMediaQuery('(min-width:600px)');

  return (
    <ContactFormContainer>
      <Box className="content" m={2}>
        <SectionTitle
          title={
            action === 'new'
              ? i18n.t('pages.contacts.form.title.new')
              : i18n.t('pages.contacts.form.title.edit')
          }
        />
        <Formik
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            showLoading();

            const handleSuccess = (message: string) => {
              setStatus({ success: true });
              setSubmitting(false);
              hideLoading();
              showSuccessAlert(message);
            };

            const handleError = (err: any, message: string) => {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              hideLoading();
              const msg = handleResponseError(err);
              showErrorAlert(msg || message);
            };

            try {
              contact.name = values.name;
              contact.email = values.email;
              contact.phone = values.phone.replace(/[^0-9]/g, '');

              await ContactService.save(contact)
                .then(() => {
                  handleSuccess(
                    action === 'new'
                      ? i18n.t('pages.contacts.alerts.save.success')
                      : i18n.t('pages.contacts.alerts.edit.success'),
                  );
                })
                .catch((err) => {
                  handleError(
                    err,
                    action === 'new'
                      ? i18n.t('pages.contacts.alerts.save.error')
                      : i18n.t('pages.contacts.alerts.edit.error'),
                  );
                });

              closeForm('save');
            } catch (err: any) {
              handleError(err, i18n.t('pages.contacts.alerts.edit.error'));
            }
          }}
          initialValues={{
            phone: contact.phone,
            name: contact.name,
            email: contact.email,
            submit: null,
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.contacts.form.fields.name')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name || ''}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.contacts.form.fields.phone')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone || ''}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: 'span 2' }}
                  InputProps={{
                    inputComponent: InputMask as any,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.contacts.form.fields.email')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ''}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 4' }}
                />
              </Box>

              <Box display="flex" justifyContent="end" mt="20px" gap={2}>
                <Button
                  type="reset"
                  color="info"
                  variant="contained"
                  onClick={() => closeForm('cancel')}
                >
                  {i18n.t('pages.contacts.form.buttons.cancel')}
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  {action === 'new'
                    ? i18n.t('pages.contacts.form.buttons.new')
                    : i18n.t('pages.contacts.form.buttons.edit')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ContactFormContainer>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object().shape({
  name: yup.string().required(i18n.t('validators.errors.required') ?? ''),
  email: yup
    .string()
    .email(i18n.t('validators.errors.email') ?? '')
    .required(i18n.t('validators.errors.required') ?? ''),
  phone: yup
    .string()
    .matches(phoneRegExp, i18n.t('validators.errors.phone') ?? '')
    .required(i18n.t('validators.errors.required') ?? ''),
});
