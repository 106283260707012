import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import {
  ContactModel,
  MessageModel,
  MessageTemplateModel,
  MessageVariable,
} from '../../@types/models';
import { MessageFormContainer } from './styles';
import * as yup from 'yup';
import i18n from '../../translate/i18n';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ptBR } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/pt-br';

import * as MessageService from '../../services/message.service';
import * as MessageTemplateService from '../../services/message-template.service';

import { tokens } from '../../theme';
import { useGlobalState } from '../../hooks/useGlobalState';
import { MessageBuilder } from '../../components/MessageBuilder';
import { ChoiceContacts, Options } from './ChoiceContacts';

export const MessageForm = ({
  message,
  action,
  contacts,
  templates,
  closeForm,
}: {
  message: MessageModel;
  action: 'new' | 'edit';
  templates: MessageTemplateModel[];
  contacts: ContactModel[];
  closeForm: (option: 'cancel' | 'save' | 'edit') => void;
}) => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const {
    showLoading,
    hideLoading,
    showErrorAlert,
    showSuccessAlert,
    handleResponseError,
  } = useGlobalState();
  moment.locale('pt-br');
  const [typeMessageValue, setTypeMessageValue] = useState('template');
  const [variables, setVariables] = useState<MessageVariable[]>([]);
  const [options, setOptions] = useState<Options[]>([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const newOptions: Options[] = contacts.map((x) => {
      return { id: x.id, label: x.name } as Options;
    });
    setOptions(newOptions);
  }, [contacts]);

  const handleChangeTypeMessage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTypeMessageValue((event.target as HTMLInputElement).value);
  };

  const handleLoadVariables = useCallback(async () => {
    await MessageTemplateService.getVariables()
      .then((data) => setVariables(data))
      .catch((err) => {
        const msg = handleResponseError(err);
        showErrorAlert(
          msg || i18n.t('pages.messageTemplates.alerts.variables.error'),
        );
      });
  }, [handleResponseError, showErrorAlert]);

  useEffect(() => {
    handleLoadVariables();
  }, [handleLoadVariables]);

  return (
    <MessageFormContainer colors={colors}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={'pt-br'}
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <Box className="content" m={2}>
          <SectionTitle
            title={
              action === 'new'
                ? i18n.t('pages.messages.form.title.new')
                : i18n.t('pages.messages.form.title.edit')
            }
          />
          <Formik
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting },
            ) => {
              showLoading();

              const handleSuccess = (message: string) => {
                setStatus({ success: true });
                setSubmitting(false);
                hideLoading();
                showSuccessAlert(message);
              };

              const handleError = (err: any, message: string) => {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                hideLoading();
                const msg = handleResponseError(err);
                showErrorAlert(msg || message);
              };

              try {
                if (action === 'new') {
                  await MessageService.create(
                    values.contacts.map((x) => x.id),
                    moment(values.sendTime).toDate(),
                    values.delay,
                    values.templateId,
                    values.message,
                  )
                    .then(() => {
                      handleSuccess(
                        i18n.t('pages.messages.alerts.save.success'),
                      );
                      closeForm('save');
                    })
                    .catch((err) => {
                      handleError(
                        err,
                        i18n.t('pages.messages.alerts.save.error'),
                      );
                    });
                } else {
                  await MessageService.update(
                    message.id,
                    values.contacts.map((x) => x.id),
                    moment(values.sendTime).toDate(),
                    values.delay,
                    values.templateId,
                    values.message,
                  )
                    .then(() => {
                      handleSuccess(
                        i18n.t('pages.messages.alerts.save.success'),
                      );
                      closeForm('edit');
                    })
                    .catch((err) => {
                      handleError(
                        err,
                        i18n.t('pages.messages.alerts.save.error'),
                      );
                    });
                }
              } catch (err: any) {
                handleError(
                  err,
                  action === 'new'
                    ? i18n.t('pages.messages.alerts.save.error')
                    : i18n.t('pages.messages.alerts.edit.error'),
                );
              }
            }}
            initialValues={{
              sendTime: message?.sendTime || '',
              templateId: message.templateId || '',
              message: message.text || '',
              delay: message.delay || 0,
              contacts: [] as Options[],
              submit: null,
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    '& > div': {
                      gridColumn: isNonMobile ? undefined : 'span 4',
                    },
                  }}
                >
                  <FormControl sx={{ gridColumn: 'span 4' }}>
                    <FormLabel id="type-message">
                      {i18n.t('pages.messages.form.fields.typeMessage')}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="type-message"
                      name="type-message-group"
                      value={typeMessageValue}
                      onChange={handleChangeTypeMessage}
                    >
                      <FormControlLabel
                        value="template"
                        control={<Radio />}
                        label={i18n.t(
                          'pages.messages.form.fields.typeMessageTemplate',
                        )}
                      />
                      <FormControlLabel
                        value="text"
                        control={<Radio />}
                        label={i18n.t(
                          'pages.messages.form.fields.typeMessageText',
                        )}
                      />
                    </RadioGroup>
                  </FormControl>
                  {typeMessageValue === 'text' && (
                    <MessageBuilder
                      variables={variables}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}

                  {typeMessageValue === 'template' && (
                    <FormControl
                      variant="filled"
                      sx={{ m: 1, minWidth: 120, gridColumn: 'span 4' }}
                    >
                      <InputLabel id="templateId">
                        {i18n.t('pages.messages.form.fields.template')}
                      </InputLabel>
                      <Select
                        labelId="templateId"
                        id="templateId"
                        value={values.templateId}
                        name="templateId"
                        error={!!touched.templateId && !!errors.templateId}
                        sx={{ gridColumn: 'span 4' }}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('templateId', e.target.value);
                        }}
                      >
                        {templates.map((x: MessageTemplateModel) => (
                          <MenuItem key={x.id} value={x.id}>
                            {x.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControl sx={{ gridColumn: 'span 2' }}>
                    <MobileDateTimePicker
                      ampm={false}
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      onChange={(newValue) => {
                        setFieldValue('sendTime', newValue?.toISOString());
                      }}
                      value={moment(values.sendTime)}
                      defaultValue={moment(Date.now())}
                      slotProps={{
                        actionBar: { sx: { color: colors.primary[400] } },
                        textField: {
                          variant: 'filled',
                          label: i18n.t('pages.messages.form.fields.sendTime'),
                          onBlur: handleBlur,
                          onChange: handleChange,
                          name: 'sendTime',
                          error: !!touched.delay && !!errors.delay,
                          helperText: touched.delay && errors.delay,
                        },
                      }}
                    />
                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label={i18n.t('pages.messages.form.fields.delay')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.delay || 0}
                    name="delay"
                    error={!!touched.delay && !!errors.delay}
                    helperText={touched.delay && errors.delay}
                    sx={{ gridColumn: 'span 2' }}
                  />

                  <FormControl sx={{ gridColumn: 'span 4' }}>
                    <ChoiceContacts
                      options={options}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px" gap={2}>
                  <Button
                    type="reset"
                    color="info"
                    variant="contained"
                    onClick={() => closeForm('cancel')}
                  >
                    {i18n.t('pages.messages.form.buttons.cancel')}
                  </Button>
                  <Button type="submit" color="secondary" variant="contained">
                    {action === 'new'
                      ? i18n.t('pages.messages.form.buttons.new')
                      : i18n.t('pages.messages.form.buttons.edit')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </LocalizationProvider>
    </MessageFormContainer>
  );
};

const validationSchema = yup.object().shape({
  sendTime: yup.date().required(i18n.t('validators.errors.required') ?? ''),
  templateId: yup.string().when('typeMessageValue', {
    is: (typeMessageValue: string) => typeMessageValue === 'template',
    then: yup.string().required(i18n.t('validators.errors.required') ?? ''),
    otherwise: yup.string(),
  }),
  message: yup.string().when('setTytypeMessageValuepeMessageValue', {
    is: (typeMessageValue: string) => typeMessageValue === 'text',
    then: yup.string().required(i18n.t('validators.errors.required') ?? ''),
    otherwise: yup.string(),
  }),
  delay: yup.number().required(i18n.t('validators.errors.required') ?? ''),
  contacts: yup.array().min(1, i18n.t('validators.errors.required') ?? ''),
});
