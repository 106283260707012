import { MessageQueueModel } from '../../@types/models';
import { DefaultGrid } from '../../components/DefaultGrid';
import { ActionButton } from '../../components/DefaultGrid/ActionButton';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import i18n from '../../translate/i18n';
import StatusColumn from './StatusColumn';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

export interface MessageQueuesGridProps {
  messageQueues: Array<MessageQueueModel>;
  handleShowDetails: (id: string) => void;
}

const MessageQueuesGrid = ({
  messageQueues,
  handleShowDetails,
}: MessageQueuesGridProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const showDetails = (item: any) => {
    handleShowDetails(item.id);
  };

  const columns = [
    {
      field: 'status',
      flex: 1,
      headerName: i18n.t('pages.messageQueues.datagrid.columns.status'),
      renderCell: ({ row: { status } }: any) => {
        return <StatusColumn status={status} colors={colors} />;
      },
    },
    {
      field: 'schedule',
      flex: 1,
      headerName: i18n.t('pages.messageQueues.datagrid.columns.schedule'),
    },
    {
      field: 'contact',
      flex: 1,
      headerName: i18n.t('pages.messageQueues.datagrid.columns.contact'),
    },
    {
      field: 'product',
      flex: 1,
      headerName: i18n.t('pages.messageQueues.datagrid.columns.product'),
    },
    {
      field: 'messageName',
      flex: 1,
      headerName: i18n.t('pages.messageQueues.datagrid.columns.messageName'),
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <ActionButton
            params={params}
            action={showDetails}
            icon={<ArticleOutlinedIcon fontSize="inherit" />}
            tooltipText={i18n.t(
              'pages.messageQueues.datagrid.columns.buttons.tooltip',
            )}
            key={`details--${params.id}`}
          />
        );
      },
    },
  ];

  return <DefaultGrid rows={messageQueues || []} columns={columns} />;
};

export default MessageQueuesGrid;
