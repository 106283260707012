import { Divider, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { SectionTitleContainer } from './styles';

export interface SectionTitleProps {
  title?: string | undefined | null;
  subtitle?: string | null;
  hideDivider?: boolean;
  uppercase?: boolean;
}

const SectionTitle = ({
  title = '',
  subtitle = '',
  hideDivider = false,
  uppercase = false,
}: SectionTitleProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <SectionTitleContainer>
      {!!title && (
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ m: '0 0 5px 0' }}
        >
          {uppercase ? title.toUpperCase() : title}
        </Typography>
      )}

      {!!subtitle && (
        <Typography variant="h5" color={colors.greenAccent[400]}>
          {subtitle}
        </Typography>
      )}

      {!hideDivider && <Divider sx={{ mt: 1 }} />}
    </SectionTitleContainer>
  );
};

export default SectionTitle;
