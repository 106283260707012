import { useCallback, useEffect, useState } from 'react';
import { Box, Drawer, useTheme } from '@mui/material';

import { MessageQueueContainer } from './styles';
import { tokens } from '../../theme';
import SearchInputBox from '../../components/SearchInputBox';
import i18n from '../../translate/i18n';

import * as MessageQueueService from '../../services/message-queue.service';

import { useGlobalState } from '../../hooks/useGlobalState';
import { MessageQueueModel } from '../../@types/models';
import MessageQueuesGrid from './MessageQueueGrid';
import { MessageQueueDetails } from './MessageQueueDetail';

export const MessageQueue = () => {
  const [messageQueues, setMessageQueues] = useState<MessageQueueModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMessageQueue, setSelectedMessageQueue] =
    useState<MessageQueueModel>({} as MessageQueueModel);

  const { showLoading, hideLoading, handleTitleSectionLabels } =
    useGlobalState();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    handleTitleSectionLabels(i18n.t('pages.messageQueues.title'), '');
  }, [handleTitleSectionLabels]);

  const handleLoadMessageQueues = useCallback(async () => {
    showLoading();
    const messageQueuesList = await MessageQueueService.list();
    setMessageQueues(messageQueuesList);
    hideLoading();
  }, [hideLoading, showLoading]);

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const handleSelectMessageQueue = (id: string) => {
    const selMessageQueue = filteredMessageQueues.find((x) => x.id === id);
    setSelectedMessageQueue(selMessageQueue || ({} as MessageQueueModel));
    handleActionForm(true);
  };

  const toggleFormDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        console.log(event);
      }
      handleActionForm(open);
    };

  useEffect(() => {
    handleLoadMessageQueues();
  }, [handleLoadMessageQueues]);

  const handleActionForm = (open: boolean) => {
    if (!open) {
      setSelectedMessageQueue({} as MessageQueueModel);
    }
    setShowDetails(open);
  };

  const handleCloseForm = () => handleActionForm(false);

  const filteredMessageQueues = searchTerm
    ? messageQueues.filter(
        (m) =>
          m.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
          m.messageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          m.contact.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : messageQueues;

  return (
    <MessageQueueContainer colors={colors}>
      <Box className="content">
        <Box className="controlBar">
          <SearchInputBox
            handleChange={handleSearchChange}
            width={350}
            placeholder={i18n.t('pages.messageQueues.search') ?? ''}
          />
        </Box>

        <MessageQueuesGrid
          messageQueues={filteredMessageQueues}
          handleShowDetails={handleSelectMessageQueue}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showDetails}
        onClose={toggleFormDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <MessageQueueDetails
          messageQueue={selectedMessageQueue}
          closeForm={handleCloseForm}
        />
      </Drawer>
    </MessageQueueContainer>
  );
};
