import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { Container } from './styles';
import { ProSidebar, Menu } from 'react-pro-sidebar';
import HeaderLogo from './HeaderLogo';
import Item from './Item';
import i18n from '../../translate/i18n';
import SectionTitleMenu from './SectionTitleMenu';

import 'react-pro-sidebar/dist/css/styles.css';

// Icons Imports
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

const SidebarMenu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');

  return (
    <Container background={colors.primary[400]}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <HeaderLogo
            color={colors.grey[100]}
            isCollapsed={isCollapsed}
            handleClick={() => setIsCollapsed(!isCollapsed)}
          />

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title={i18n.t('sidebarMenu.dashboard')}
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {!isCollapsed && (
              <SectionTitleMenu
                title={i18n.t('sidebarMenu.separator.automaitons')}
                color={colors.grey[300]}
              />
            )}

            {/* <Item
              title={i18n.t('sidebarMenu.messageQueue')}
              to="/messagesQueue"
              icon={<QuestionAnswerOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={i18n.t('sidebarMenu.conversionFunnel')}
              to="/"
              icon={<FilterAltOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Item
              title={i18n.t('sidebarMenu.quickMessages')}
              to="/messages"
              icon={<QuickreplyOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {!isCollapsed && (
              <SectionTitleMenu
                title={i18n.t('sidebarMenu.separator.configs')}
                color={colors.grey[300]}
              />
            )}

            <Item
              title={i18n.t('sidebarMenu.users')}
              to="/users"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={i18n.t('sidebarMenu.products')}
              to="/products"
              icon={<Inventory2OutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={i18n.t('sidebarMenu.messages')}
              to="/templates"
              icon={<MessageOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={i18n.t('sidebarMenu.contacts')}
              to="/contacts"
              icon={<ContactPhoneOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Container>
  );
};

export default SidebarMenu;
