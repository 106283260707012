import { Box } from '@mui/system';
import styled from 'styled-components';

export interface SearchInputContainerProps {
  background: string;
}

export const SearchInputContainer = styled(Box)<SearchInputContainerProps>`
  display: flex;
  background-color: ${({ background }) => background};
  border-radius: 3px;
`;
