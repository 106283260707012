import {
  ContactModel,
  MessageModel,
  MessageTemplateModel,
  ProductModel,
  UserModel,
} from '../@types/models';

export const toUser = (item: any, signatureId = ''): UserModel => {
  return {
    id: item.id,
    name: item.nome,
    email: item.email,
    phone: item.celular,
    userType: item.tipo,
    signature: signatureId || undefined,
  } as UserModel;
};

export const toProduct = (item: any): ProductModel => {
  return {
    id: item.id,
    description: item.nome,
    signatureId: item.assinaturaId,
    createDate: item.dataCriacao,
    updateDate: item.dataAtualizacao,
  } as ProductModel;
};

export const toContact = (item: any): ContactModel => {
  return {
    id: item.id,
    userId: item.usuarioId,
    name: item.nome,
    email: item.email,
    phone: item.celular,
    status: item.status,
    statusCode: item.codigoStatus,
  } as ContactModel;
};

export const toMessageTemplate = (item: any): MessageTemplateModel => {
  return {
    id: item.id,
    name: item.nome,
    body: item.corpo,
    delay: item.segundosDelay,
    createDate: item.dataCriacao,
    updateDate: item.dataAtualizacao,
  } as MessageTemplateModel;
};

export const toMessage = (item: any): MessageModel => {
  return {
    id: item.id,
    status: item.status,
    sendTime: item.horarioParaSerEnviado,
    text: item.texto,
    delay: item.segundosDelay,
    templateId: item.templateId,
    template: item.template,
    userId: item.usuarioId,
    userName: item.nomeUsuario,
    createDate: item.dataCriacao,
    updateDate: item.dataAtualizacao,
  } as MessageModel;
};
