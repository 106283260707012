import { Box, Button, Drawer, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  ContactModel,
  MessageModel,
  MessageTemplateModel,
  MessageViewModel,
} from '../../@types/models';
import SearchInputBox from '../../components/SearchInputBox';
import { tokens } from '../../theme';
import i18n from '../../translate/i18n';
import { MessageForm } from './MessageForm';
import { MessagesGrid } from './MessagesGrid';
import { MessagesContainer } from './styles';

import * as MessageService from '../../services/message.service';
import * as MessageTemplateService from '../../services/message-template.service';
import * as ContactService from '../../services/contact.service';

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { useGlobalState } from '../../hooks/useGlobalState';
import { ConfirmAction } from '../../components/CofirmAction';

export const Messages = () => {
  const [messages, setMessages] = useState<MessageViewModel[]>([]);
  const [templates, setTemplates] = useState<MessageTemplateModel[]>([]);
  const [contacts, setContacts] = useState<ContactModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState('');
  const [actionForm, setActionForm] = useState<'new' | 'edit'>('new');
  const [selectedMessage, setSelectedMessage] = useState<MessageModel>(
    {} as MessageModel,
  );

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    showSuccessAlert,
    showErrorAlert,
    showLoading,
    hideLoading,
    handleTitleSectionLabels,
    handleResponseError,
  } = useGlobalState();

  useEffect(() => {
    handleTitleSectionLabels(
      i18n.t('pages.messages.title'),
      i18n.t('pages.messages.subtitle'),
    );
  }, [handleTitleSectionLabels]);

  const handleLoadMessages = useCallback(async () => {
    await MessageService.list()
      .then((itens) => {
        setMessages(itens);
      })
      .catch((error) => handleResponseError(error));
  }, [handleResponseError]);

  const handleLoadTemplates = useCallback(async () => {
    await MessageTemplateService.list()
      .then((itens) => {
        setTemplates(itens);
      })
      .catch((error) => handleResponseError(error));
  }, [handleResponseError]);

  const handleLoadContacts = useCallback(async () => {
    await ContactService.list()
      .then((itens) => {
        setContacts(itens);
      })
      .catch((error) => handleResponseError(error));
  }, [handleResponseError]);

  const handleLoadMessage = useCallback(
    async (id: string) => {
      await MessageService.getById(id)
        .then((item) => {
          setSelectedMessage(item);
        })
        .catch((error) => {
          const msg = handleResponseError(error);
          showErrorAlert(msg || i18n.t('pages.messages.alerts.loading.error'));
        });
    },
    [handleResponseError, showErrorAlert],
  );

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const handleSelectMessage = async (id: string) => {
    showLoading();
    await handleLoadMessage(id);
    setActionForm('edit');
    hideLoading();
    handleActionForm(true);
  };

  const handleRemoveMessage = async (id: string) => {
    setSelectedMessageId(id);
    setShowConfirm(true);
  };

  const handleConfirmAction = () => {
    setShowConfirm(false);
    setSelectedMessageId('');
  };

  const removeMessage = async (id: string) => {
    showLoading();
    try {
      await MessageService.remove(id)
        .then((res) => {
          if (res) {
            showSuccessAlert(i18n.t('pages.messages.alerts.delete.success'));
            handleLoadMessages();
            hideLoading();
          }
        })
        .catch((error) => {
          showErrorAlert(error.message);
          hideLoading();
        });
    } catch (error: any) {
      showErrorAlert(error.message);
      hideLoading();
    }
  };

  const toggleFormDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        console.log(event);
      }
      handleActionForm(open);
    };

  useEffect(() => {
    handleLoadMessages();
    handleLoadContacts();
    handleLoadTemplates();
  }, [handleLoadContacts, handleLoadMessages, handleLoadTemplates]);

  const handleActionForm = (open: boolean) => {
    if (!open) {
      setSelectedMessage({} as MessageModel);
      setActionForm('new');
    }
    setShowForm(open);
  };

  const handleCloseForm = (option: 'cancel' | 'save' | 'edit') => {
    if (option === 'save' || option === 'edit') {
      handleLoadMessages();
    }
    handleActionForm(false);
  };

  const filteredMessages = searchTerm
    ? messages.filter(
        (m) =>
          m.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          m.template.toLowerCase().includes(searchTerm.toLowerCase()) ||
          m.sendTime
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
      )
    : messages;

  return (
    <MessagesContainer colors={colors}>
      <Box className="content">
        <Box className="controlBar">
          <SearchInputBox
            handleChange={handleSearchChange}
            width={350}
            placeholder={i18n.t('pages.messages.search') ?? ''}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<MessageOutlinedIcon />}
            onClick={toggleFormDrawer(true)}
          >
            {i18n.t('pages.messages.new')}
          </Button>
        </Box>

        <MessagesGrid
          messages={filteredMessages}
          handleSelectedMessage={handleSelectMessage}
          handleRemoveMessage={handleRemoveMessage}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showForm}
        onClose={toggleFormDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <MessageForm
          contacts={contacts}
          templates={templates}
          message={selectedMessage}
          action={actionForm}
          closeForm={handleCloseForm}
        />
      </Drawer>
      <ConfirmAction
        open={showConfirm}
        title={i18n.t('confirmAction.title')}
        body={i18n.t('confirmAction.cancelation', { resource: 'da mensagem' })}
        closeAction={() => handleConfirmAction()}
        handleConfirm={async () => {
          await removeMessage(selectedMessageId);
          handleConfirmAction();
        }}
      />
    </MessagesContainer>
  );
};
