import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertBox } from '../../components/AlertBox';
import { Loading } from '../../components/Loading';
import SidebarMenu from '../../components/SidebarMenu';
import Topbar from '../../components/Topbar';
import { useAuth } from '../../hooks/useAuth';
import { MainContentLayout } from './styles';

export const DashboardLayout = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.email) {
    navigate('/login');
  }

  return (
    <React.Fragment>
      <>
        <SidebarMenu />
        <main className="content">
          <Topbar />
          <MainContentLayout>{children}</MainContentLayout>
          <AlertBox />
          <Loading />
        </main>
      </>
    </React.Fragment>
  );
};
