import { Box } from '@mui/system';
import styled from 'styled-components';
import { SearchInputProps } from './SearchInputBox';

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 19px 29px -3px rgba(0,0,0,0.1),12px 10px 15px -3px rgba(0,0,0,0.1);
`;

export const SearchInputContainer = styled(Box)<SearchInputProps>`
  display: flex;
  background-color: ${({ background }) => background};
  border-radius: 3px;
`;

export const IconBoxContainer = styled(Box)`
  display: flex;
`;
