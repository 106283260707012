import { Box, Button, Drawer, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import i18n from '../../translate/i18n';
import { Container } from './styles';
import SearchInputBox from '../../components/SearchInputBox';
import { useCallback, useEffect, useState } from 'react';

import { useGlobalState } from '../../hooks/useGlobalState';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import UserForm from './UserForm';
import UsersGrid from './UsersGrid';
import { UserModel } from '../../@types/models';

import * as UserService from '../../services/users.service';
import { useAuth } from '../../hooks/useAuth';
import { ConfirmAction } from '../../components/CofirmAction';

const Users = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionForm, setActionForm] = useState<'new' | 'edit'>('new');
  const [selectedUser, setSelectedUser] = useState<UserModel>({} as UserModel);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { data } = useAuth();

  const {
    handleTitleSectionLabels,
    handleResponseError,
    showErrorAlert,
    showSuccessAlert,
    showLoading,
    hideLoading,
  } = useGlobalState();

  useEffect(() => {
    handleTitleSectionLabels(
      i18n.t('pages.users.title'),
      i18n.t('pages.users.subtitle'),
    );
  }, [handleTitleSectionLabels]);

  const handleLoadUsers = useCallback(async () => {
    await UserService.list()
      .then((res) => {
        setUsers(res);
      })
      .catch((error) => {
        handleResponseError(error);
      });
  }, [handleResponseError]);

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const handleSelectUser = (id: string) => {
    const selItem = filteredUsers.find((x) => x.id === id);
    setActionForm('edit');
    setSelectedUser(selItem || ({} as UserModel));
    handleActionForm(true);
  };

  const handleRemoveUser = async (id: string) => {
    const selectedItem = filteredUsers.find((x) => x.id === id);
    if (selectedItem) {
      setSelectedUser(selectedItem);
      setShowConfirm(true);
    }
  };

  const handleConfirmAction = () => {
    setShowConfirm(false);
    setSelectedUser({} as UserModel);
  };

  const removeUser = async (id: string) => {
    showLoading();
    try {
      await UserService.remove(id)
        .then((res) => {
          if (res) {
            showSuccessAlert(i18n.t('pages.users.alerts.delete.success'));
            handleLoadUsers();
            hideLoading();
          }
        })
        .catch((error) => {
          handleResponseError(error);
          hideLoading();
        });
    } catch (error: any) {
      showErrorAlert(error.message);
      hideLoading();
    }
  };

  useEffect(() => {
    handleLoadUsers();
  }, [handleLoadUsers]);

  const handleActionForm = (open: boolean) => {
    if (!open) {
      setSelectedUser({} as UserModel);
      setActionForm('new');
    }
    setShowForm(open);
  };

  const handleCloseForm = (option: 'cancel' | 'save' | 'edit') => {
    if (option === 'save' || option === 'edit') {
      handleLoadUsers();
    }
    handleActionForm(false);
  };

  const toggleUserDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setShowForm(open);
    };

  const filteredUsers = searchTerm
    ? users.filter(
        (p) =>
          p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.phone.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : users;

  return (
    <Container colors={colors}>
      <Box className="content">
        <Box className="controlBar">
          <SearchInputBox
            handleChange={handleSearchChange}
            width={350}
            placeholder={i18n.t('topbarMenu.search') ?? ''}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PersonAddAlt1OutlinedIcon />}
            onClick={() => {
              if (
                data?.userType === 'SemCelular' ||
                data?.userType === 'ComCelular'
              ) {
                showErrorAlert(i18n.t('response.errors.error403'));
              } else {
                handleActionForm(true);
              }
            }}
          >
            Novo Usuário
          </Button>
        </Box>
        <UsersGrid
          users={users}
          handleSelectedUser={handleSelectUser}
          handleRemoveUser={handleRemoveUser}
        />
      </Box>
      <Drawer
        anchor="right"
        open={showForm}
        onClose={toggleUserDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <UserForm
          user={selectedUser}
          action={actionForm}
          closeForm={handleCloseForm}
        />
      </Drawer>
      <ConfirmAction
        open={showConfirm}
        title={i18n.t('confirmAction.title')}
        body={i18n.t('confirmAction.delete', { resource: 'do usuário' })}
        closeAction={() => handleConfirmAction()}
        handleConfirm={async () => {
          await removeUser(selectedUser.id);
          handleConfirmAction();
        }}
      />
    </Container>
  );
};

export default Users;
