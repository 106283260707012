import { useGlobalState } from '../../hooks/useGlobalState';
import SectionTitle from '../SectionTitle';
import IconsBox from './IconsBox';
// import SearchInputBox from './SearchInputBox';
import { Container } from './styles';

const Topbar = () => {
  const { title } = useGlobalState();

  return (
    <Container p={2}>
      <SectionTitle title={title} hideDivider={true} uppercase={true} />
      <IconsBox />
    </Container>
  );
};

export default Topbar;
