import { StatusColumnContainer } from './styles';
import { Typography } from '@mui/material';
import i18n from '../../translate/i18n';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';

export interface StatusColumnContainerProps {
  status: string;
  colors: any;
}

const StatusColumn = ({ status, colors }: StatusColumnContainerProps) => {
  console.log(status);
  return (
    <StatusColumnContainer status={status} colors={colors}>
      {status === 'Enviada' && <SendOutlinedIcon />}
      {status === 'Pendente' && <ScheduleSendOutlinedIcon />}
      {status === 'Cancelada' && <CancelScheduleSendOutlinedIcon />}
      {status === 'ComFalha ' && <ReportOffOutlinedIcon />}
      <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
        {status === 'Enviada' &&
          i18n.t('pages.messages.datagrid.columns.statusTypes.send')}
        {status === 'Pendente' &&
          i18n.t('pages.messages.datagrid.columns.statusTypes.await')}
        {status === 'Cancelada' &&
          i18n.t('pages.messages.datagrid.columns.statusTypes.cancel')}
        {status === 'ComFalha' &&
          i18n.t('pages.messages.datagrid.columns.statusTypes.error')}
      </Typography>
    </StatusColumnContainer>
  );
};

export default StatusColumn;
