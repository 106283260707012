import { Backdrop, CircularProgress } from '@mui/material';
import { useGlobalState } from '../../hooks/useGlobalState';

export const Loading = () => {
  const { toggleLoading, hideLoading } = useGlobalState();
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
      open={toggleLoading}
      onClick={hideLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
