export const messages = {
  pt: {
    translations: {
      sidebarMenu: {
        projectName: 'Inteligente Zap',
        dashboard: 'Dashboard',
        messageQueue: 'Fila de mensagens',
        conversionFunnel: 'Funil de conversão',
        quickMessages: 'Enviar Mensagens',
        users: 'Atendentes',
        products: 'Produtos',
        messages: 'Mensagens',
        contacts: 'Contatos',
        separator: {
          automaitons: 'Automações',
          configs: 'Configurações',
        },
      },
      topbarMenu: {
        search: 'Pesquisar',
        iconDark: 'O tema escuro está ativo',
        iconLight: 'O tema claro está ativo',
        account: {
          greetings: {
            morning: 'Bom dia',
            afternoon: 'Boa tarde',
            evening: 'Boa noite',
          },
          userType: {
            AdminAssinatura: 'Administrador',
            AdminGeral: 'Administrador Geral',
            SemCelular: 'Atendente',
            ComCelular: 'Atendente',
          },
          logout: 'Logout',
        },
      },
      pages: {
        dashboard: {
          title: 'Dashboard',
        },
        users: {
          title: 'Atendentes',
          subtitle: 'Listagens de usuários/atendentes',
          datagrid: {
            columns: {
              name: 'Nome',
              phone: 'Telefone',
              email: 'E-mail',
              access: 'Nível',
              acesss_level: {
                admin: 'Admin',
                users: 'Atendente',
              },
            },
          },
          form: {
            title: {
              new: 'Novo Usuário',
            },
            name: 'Nome',
            phone: 'Telefone',
            email: 'E-mail',
            password: 'Senha',
            userType: {
              label: 'Tipo',
              types: {
                AdminAssinatura: 'Administrador',
                AdminGeral: 'Administrador Geral',
                SemCelular: 'Atendente',
                ComCelular: 'Atendente(Celular)',
              },
            },
            buttons: {
              cancel: 'Cancelar',
              new: 'Adicionar',
              edit: 'Editar',
            },
          },
        },
        login: {
          title: 'LOGIN',
          email: 'E-mail',
          password: 'Senha',
          forgot_password: 'Esqueceu sua senha?',
          button: 'Entrar',
          toggle_visibility: 'Mostrar/Ocultar senha',
          messages: {
            error: 'E-mail e/ou senha inválido(s)',
          },
        },
        products: {
          title: 'Produtos',
          subtitle: 'Listagens de produtos',
          search: 'Pesquisar',
          new: 'Novo Produto',
          datagrid: {
            columns: {
              description: 'Descrição',
            },
            buttons: {
              details: 'Exibir detalhes',
            },
          },
          form: {
            title: {
              new: 'Novo Produto',
              edit: 'Editar produto',
            },
            description: 'Descrição',
            buttons: {
              cancel: 'Cancelar',
              new: 'Adicionar',
              edit: 'Editar',
            },
          },
          alerts: {
            delete: {
              success: 'Produto excluído com sucesso',
              error: 'Falha ao excluir o produto',
            },
            save: {
              success: 'Produto salvo com sucesso',
              error: 'Falha ao salvar o produto',
            },
            edit: {
              success: 'Produto alterado com sucesso',
              error: 'Falha ao alterar o produto',
            },
          },
        },
        contacts: {
          title: 'Contatos',
          new: 'Novo contato',
          subtitle: 'Listagens de contatos',
          search: 'Pesquisar por nome, email ou telefone',
          datagrid: {
            columns: {
              name: 'Nome',
              phone: 'Telefone',
              email: 'E-mail',
            },
          },
          form: {
            title: {
              new: 'Novo Contato',
              edit: 'Editar contato',
            },
            fields: {
              name: 'Nome',
              phone: 'Telefone',
              email: 'E-mail',
            },
            buttons: {
              cancel: 'Cancelar',
              new: 'Adicionar',
              edit: 'Editar',
            },
          },
          alerts: {
            delete: {
              success: 'Contato excluído com sucesso',
              error: 'Falha ao excluir o contato',
            },
            save: {
              success: 'Contato salvo com sucesso',
              error: 'Falha ao salvar o contaot',
            },
            edit: {
              success: 'Contato alterado com sucesso',
              error: 'Falha ao alterar o contato',
            },
          },
        },
        messages: {
          title: 'Envio de Mensagens',
          subtitle: 'Listagens de mensagens',
          search: 'Pesquisar',
          new: 'Nova mensagem',
          datagrid: {
            columns: {
              name: 'Nome',
              order: 'Ordem',
              status: 'Status',
              sendTime: 'Data de envio',
              numberContacts: 'Qtde Contatos',
              isTemplate: 'Usa Template?',
              template: 'Template',
              statusTypes: {
                send: 'Enviada',
                await: 'Agendada',
                cancel: 'Cancelada',
                error: 'Falhou',
              },
            },
          },
          form: {
            title: {
              new: 'Nova mensagem',
              edit: 'Editar mensagem',
            },
            labels: {
              noOptionsText: 'Nenhum resultado encontrado.',
            },
            fields: {
              typeMessage: 'Tipo de Mensagem',
              typeMessageTemplate: 'Template',
              typeMessageText: 'Texto',
              sendTime: 'Enviar em',
              message: 'Mensagem',
              delay: 'Segundos delay',
              template: 'Template',
              contacts: 'Contato(s)',
            },
            buttons: {
              cancel: 'Cancelar',
              new: 'Adicionar',
              edit: 'Editar',
            },
          },
          alerts: {
            delete: {
              success: 'Mensagem cancelada com sucesso',
              error: 'Falha ao cancelada a mensagem',
            },
            save: {
              success: 'Mensagem salva com sucesso',
              error: 'Falha ao salvar a mensagem',
            },
            edit: {
              success: 'Mensagem alterada com sucesso',
              error: 'Falha ao alterar a mensagem',
            },
            loading: {
              error: 'Falha ao buscar os dados da mensagem',
            },
          },
        },
        messageTemplates: {
          title: 'Templates de mensagens',
          subtitle: 'Listagens de mensagens',
          search: 'Pesquisar',
          new: 'Novo template',
          datagrid: {
            columns: {
              name: 'Template',
            },
          },
          form: {
            title: {
              new: 'Nova template de mensagem',
              edit: 'Editar template de mensagem',
            },
            fields: {
              delay: 'Segundos de delay',
              name: 'Nome da mensagem',
              message: 'Mensagem',
              order: 'Ordem',
              variable: 'Inserir variável',
            },
            buttons: {
              cancel: 'Cancelar',
              new: 'Adicionar',
              edit: 'Editar',
            },
          },
          alerts: {
            delete: {
              success: 'Mensagem excluída com sucesso',
              error: 'Falha ao excluir a mensagem',
            },
            save: {
              success: 'Mensagem salva com sucesso',
              error: 'Falha ao salvar a mensagem',
            },
            edit: {
              success: 'Mensagem alterada com sucesso',
              error: 'Falha ao alterar a mensagem',
            },
            variables: {
              error: 'Falha ao buscar os dados das variáveis',
            },
            loading: {
              error: 'Falha ao buscar os dados do template',
            },
          },
        },
        messageQueues: {
          title: 'Fila de mensagens',
          search: 'Pesquisar',
          datagrid: {
            columns: {
              status: 'Status',
              schedule: 'Horário',
              contact: 'Cliente',
              product: 'Produto',
              messageName: 'Mensagem',
              statusTypes: {
                send: 'Enviada',
                await: 'Agendada',
              },
              buttons: {
                tooltip: 'Exibir detalhes',
              },
            },
          },
          details: {
            title: 'Detalhes',
            buttons: {
              cancel: 'Voltar',
            },
          },
        },
      },
      validators: {
        errors: {
          required: 'Campo Obrigatório',
          email: 'E-mail inválido',
          phone: 'Número de telefone inválido',
          max: 'O tamanho máximo do campo é de {{max}} caracteres.',
          min: 'O tamanho mínimo do campo é de {{min}} caracteres.',
        },
      },
      datagrid: {
        default: {
          labelDisplayedRows: '{{from}} - {{to}} de {{count}}',
          labelRowsPerPage: 'Linhas por página',
          noResultsOverlayLabel: 'Nenhum resultado encontrado.',
          noRowsLabel: 'Nenhuma linha',
        },
        actionButtons: {
          delete: 'Excluir',
          edit: 'Editar',
        },
      },
      footer: {
        terms: 'TERMOS DE SERVIÇO',
        about_copyright: 'SOBRE OS DIREITOS AUTORIAIS',
        private_policy: 'POLÍTICA DE PRIVACIDADE',
        copyright: 'Todos os direitos reservardos',
      },
      response: {
        errors: {
          error401: 'Sessão expirada! Faça login novamente...',
          error403:
            'Sem permissão para acessar esse recurso, entre em contato com o Admin do sistema.',
        },
      },
      confirmAction: {
        title: 'Atenção!',
        delete: 'Confirma a exclusão {{resource}}?',
        cancelation: 'Confirma o cancelamento {{resource}}?',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
  },
};
