import * as React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { MessageVariable } from '../../@types/models';
import i18n from '../../translate/i18n';

export interface VariableProps {
  variables: MessageVariable[];
  handleSelectedVariable: (value: MessageVariable) => void;
}

export const VariableSelectMenu = ({
  variables,
  handleSelectedVariable,
}: VariableProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: MessageVariable) => {
    handleSelectedVariable(value);
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="space-between" mt="10px">
      <Button
        id="var-button"
        aria-controls={open ? 'var-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
      >
        {i18n.t('pages.messageTemplates.form.fields.variable')}
      </Button>
      <Menu
        id="var-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'var-button',
        }}
      >
        {variables.map((x) => (
          <MenuItem onClick={() => handleClose(x)} key={x.value}>
            {x.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
