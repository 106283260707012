import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { ProductModel } from '../../@types/models';
import { ProductFormContainer } from './styles';
import * as yup from 'yup';
import i18n from '../../translate/i18n';
import { Button, TextField, useMediaQuery } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';

import * as ProductService from '../../services/product.service';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../hooks/useGlobalState';

export const ProductForm = ({
  product,
  action,
  closeForm,
}: {
  product: ProductModel;
  action: 'new' | 'edit' | 'show';
  closeForm: (option: 'cancel' | 'save' | 'edit') => void;
}) => {
  const {
    showLoading,
    hideLoading,
    showErrorAlert,
    showSuccessAlert,
    handleResponseError,
  } = useGlobalState();
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const { data } = useAuth();

  return (
    <ProductFormContainer>
      <Box className="content" m={2}>
        <SectionTitle
          title={
            action === 'new'
              ? i18n.t('pages.products.form.title.new')
              : i18n.t('pages.products.form.title.edit')
          }
        />
        <Formik
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            showLoading();

            const handleSuccess = (message: string) => {
              setStatus({ success: true });
              setSubmitting(false);
              hideLoading();
              showSuccessAlert(message);
            };

            const handleError = (err: any, message: string) => {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              hideLoading();
              handleResponseError(err);
              showErrorAlert(message);
            };

            try {
              product.description = values.description;

              if (action === 'new') {
                await ProductService.create({
                  name: values.description,
                  signature: data?.signature,
                })
                  .then((res) => {
                    console.log(res);
                    handleSuccess(i18n.t('pages.products.alerts.save.success'));
                  })
                  .catch((err) =>
                    handleError(
                      err,
                      i18n.t('pages.products.alerts.save.error'),
                    ),
                  );
              } else {
                await ProductService.update(product)
                  .then((res) => {
                    console.log(res);
                    handleSuccess(i18n.t('pages.products.alerts.edit.success'));
                  })
                  .catch((err) =>
                    handleError(
                      err,
                      i18n.t('pages.products.alerts.edit.error'),
                    ),
                  );
              }

              closeForm(action === 'new' ? 'save' : 'edit');
            } catch (err: any) {
              handleError(
                err,
                action === 'new'
                  ? i18n.t('pages.products.alerts.save.error')
                  : i18n.t('pages.products.alerts.edit.error'),
              );
            }
          }}
          initialValues={{
            description: product.description,
            submit: null,
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={i18n.t('pages.products.form.description')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description || ''}
                  name="description"
                  id="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{ gridColumn: 'span 4' }}
                />
              </Box>

              <Box display="flex" justifyContent="end" mt="20px" gap={2}>
                <Button
                  type="reset"
                  color="info"
                  variant="contained"
                  onClick={() => closeForm('cancel')}
                >
                  {i18n.t('pages.products.form.buttons.cancel')}
                </Button>
                {action !== 'show' && (
                  <Button type="submit" color="secondary" variant="contained">
                    {action === 'new'
                      ? i18n.t('pages.products.form.buttons.new')
                      : i18n.t('pages.products.form.buttons.edit')}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ProductFormContainer>
  );
};

const validationSchema = yup.object().shape({
  description: yup
    .string()
    .required(i18n.t('validators.errors.required') ?? '')
    .max(150, i18n.t('validators.errors.max', { max: 150 }) ?? ''),
});
