import * as React from 'react';
import { IMaskInput } from 'react-imask';

interface InputMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const InputMask = React.forwardRef<HTMLElement, InputMaskProps>(
  function InputMask(props: InputMaskProps, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+55 (#0) 00000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  },
);
