import { useTheme } from '@mui/material';
import { UserModel } from '../../@types/models';
import { DefaultGrid } from '../../components/DefaultGrid';
import { ActionButton } from '../../components/DefaultGrid/ActionButton';
import { ActionButtonsColumn } from '../../components/DefaultGrid/ActionButtonsColumn';
import { tokens } from '../../theme';
import i18n from '../../translate/i18n';
import AccessColumn from './AccessColumn';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useAuth } from '../../hooks/useAuth';
import { formatPhoneNumber } from '../../utils/utils';

export interface UsersGridProps {
  users: Array<UserModel>;
  handleSelectedUser: (id: string, action: 'edit' | 'show') => void;
  handleRemoveUser: (id: string) => void;
}

const UsersGrid = ({
  users,
  handleSelectedUser,
  handleRemoveUser,
}: UsersGridProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data } = useAuth();

  const showItem = (item: any) => {
    handleSelectedUser(item.id, 'show');
  };

  const editItem = (item: any) => {
    handleSelectedUser(item.id, 'edit');
  };

  const deleteItem = async (item: any) => {
    handleRemoveUser(item.id);
  };

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('pages.users.datagrid.columns.name'),
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'phone',
      headerName: i18n.t('pages.users.datagrid.columns.phone'),
      flex: 1,
      renderCell: (params: any) => {
        return formatPhoneNumber(params.value);
      },
    },
    {
      field: 'email',
      headerName: i18n.t('pages.users.datagrid.columns.email'),
      flex: 1,
    },
    {
      field: 'userType',
      headerName: i18n.t('pages.users.datagrid.columns.access'),
      flex: 1,
      renderCell: ({ row: { userType } }: any) => {
        return <AccessColumn access={userType} colors={colors} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            {data?.userType === 'AdminAssinatura' ||
            data?.userType === 'AdminGeral' ? (
              <ActionButtonsColumn
                params={params}
                actionDelete={deleteItem}
                actionEdit={editItem}
              />
            ) : (
              <ActionButton
                params={params}
                action={showItem}
                icon={<VisibilityOutlinedIcon fontSize="inherit" />}
                tooltipText={i18n.t(
                  'pages.products.datagrid.columns.buttons.details',
                )}
                key={`details--${params.id}`}
              />
            )}
          </>
        );
      },
    },
  ];

  return <DefaultGrid rows={users || []} columns={columns} />;
};

export default UsersGrid;
