import { HeaderContainer } from './styles';
import { Avatar, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import i18n from '../../translate/i18n';

const Header = () => {
  return (
    <HeaderContainer>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h3">
        {i18n.t('pages.login.title')}
      </Typography>
    </HeaderContainer>
  );
};

export default Header;
