import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { ColorModeContext, useMode } from './theme';

import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import { DashboardLayout } from './layouts/DashboardLayout';
import SignIn from './pages/SignIn';
import { Products } from './pages/Products';
import { GlobalStateProvider } from './context/GlobalProvider';
import { Contacts } from './pages/Contatcts';
import { Messages } from './pages/Messages';
import { MessageQueue } from './pages/MessageQueue';
import { MessageTemplates } from './pages/MessageTemplates';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <GlobalStateProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <DashboardLayout>
                    <Dashboard />
                  </DashboardLayout>
                }
              />
              <Route
                path="/users"
                element={
                  <DashboardLayout>
                    <Users />
                  </DashboardLayout>
                }
              />
              <Route
                path="/products"
                element={
                  <DashboardLayout>
                    <Products />
                  </DashboardLayout>
                }
              />
              <Route
                path="/contacts"
                element={
                  <DashboardLayout>
                    <Contacts />
                  </DashboardLayout>
                }
              />
              <Route
                path="/messages"
                element={
                  <DashboardLayout>
                    <Messages />
                  </DashboardLayout>
                }
              />
              <Route
                path="/templates"
                element={
                  <DashboardLayout>
                    <MessageTemplates />
                  </DashboardLayout>
                }
              />
              <Route
                path="/messagesQueue"
                element={
                  <DashboardLayout>
                    <MessageQueue />
                  </DashboardLayout>
                }
              />
              <Route path="/" element={<SignIn />} />
              <Route path="/login" element={<SignIn />} />
            </Routes>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </GlobalStateProvider>
  );
}

export default App;
