import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useGlobalState } from '../../hooks/useGlobalState';
import i18n from '../../translate/i18n';

const Dashboard = () => {
  const { handleTitleSectionLabels } = useGlobalState();

  useEffect(() => {
    handleTitleSectionLabels(i18n.t('pages.dashboard.title'), '');
  }, [handleTitleSectionLabels]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <SectionTitle
          title={i18n.t('pages.dashboard.title')}
          subtitle="Olaaaa enfermeira"
        /> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
