import { MessageQueueModel } from '../@types/models';
import * as Utils from '../utils/utils';

export const list = async (): Promise<MessageQueueModel[]> => {
  try {
    await Utils.sleep(2000);
    return new Promise<MessageQueueModel[]>((resolve) => {
      resolve(dataListMock);
    });
  } catch (error) {
    // trativa dos erros deve ser implementada
    return [];
  }
};

export const details = (id: string) => {
  console.log(id);
};

const dataListMock = [
  {
    id: 'GUID--001',
    status: 'enviada',
    schedule: new Date().toLocaleString(),
    product: 'CURSO MASTER',
    contact: 'João da Silva',
    messageFunnel: 'API/Personalizado',
    messageName: 'Saudação',
    attendant: 'Cristina',
  },
  {
    id: 'GUID--002',
    status: 'agendada',
    schedule: new Date().toLocaleString(),
    product: 'CURSO MASTER',
    contact: 'José da Silva',
    messageFunnel: 'API/Personalizado',
    messageName: 'Saudação',
    attendant: 'Cristina',
  },
  {
    id: 'GUID--003',
    status: 'agendada',
    schedule: new Date().toLocaleString(),
    product: 'CURSO MASTER',
    contact: 'Maria da Silva',
    messageFunnel: 'API/Personalizado',
    messageName: 'Saudação',
    attendant: 'Cristina',
  },
] as MessageQueueModel[];
