import { Box, Button, Drawer, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import SearchInputBox from '../../components/SearchInputBox';
import { tokens } from '../../theme';
import i18n from '../../translate/i18n';
import { ContactsContainer } from './styles';

import * as ContactService from '../../services/contact.service';

import { ContactModel } from '../../@types/models';
import ContactsGrid from './ContactsGrid';
import { ContactForm } from './ContactForm';
import { useGlobalState } from '../../hooks/useGlobalState';

import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

export const Contacts = () => {
  const [contacts, setContacts] = useState<ContactModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [actionForm, setActionForm] = useState<'new' | 'edit' | 'show'>('new');
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ContactModel>(
    {} as ContactModel,
  );

  const { handleTitleSectionLabels, handleResponseError } = useGlobalState();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    handleTitleSectionLabels(
      i18n.t('pages.contacts.title'),
      i18n.t('pages.contacts.subtitle'),
    );
  }, [handleTitleSectionLabels]);

  const handleLoadContacts = useCallback(async () => {
    await ContactService.list()
      .then((items) => {
        setContacts(items);
      })
      .catch((err) => handleResponseError(err));
  }, [handleResponseError]);

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const handleSelectContact = (id: string, action: 'edit' | 'show') => {
    const selContact = filteredContacts.find((x) => x.id === id);
    setActionForm(action);
    setSelectedContact(selContact || ({} as ContactModel));
    handleActionForm(true);
  };

  const toggleFormDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        console.log(event);
      }
      handleActionForm(open);
    };

  useEffect(() => {
    handleLoadContacts();
  }, [handleLoadContacts]);

  const filteredContacts = searchTerm
    ? contacts.filter(
        (p) =>
          p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.email.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : contacts;

  const handleActionForm = (open: boolean) => {
    if (!open) {
      setSelectedContact({} as ContactModel);
      setActionForm('new');
    }
    setShowContactForm(open);
  };

  const handleCloseForm = (option: 'cancel' | 'save' | 'edit') => {
    if (option === 'save' || option === 'edit') {
      handleLoadContacts();
    }
    handleActionForm(false);
  };

  return (
    <ContactsContainer colors={colors}>
      <Box className="content">
        <Box className="controlBar">
          <SearchInputBox
            handleChange={handleSearchChange}
            width={350}
            placeholder={i18n.t('pages.contacts.search') ?? ''}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ContactPhoneOutlinedIcon />}
            onClick={() => handleActionForm(true)}
          >
            {i18n.t('pages.contacts.new')}
          </Button>
        </Box>

        <ContactsGrid
          contacts={filteredContacts}
          handleSelectedContact={handleSelectContact}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showContactForm}
        onClose={toggleFormDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <ContactForm
          contact={selectedContact}
          action={actionForm}
          closeForm={handleCloseForm}
        />
      </Drawer>
    </ContactsContainer>
  );
};
