import { SectionTitleContainer } from './styles';

export interface SectionTitleProps {
  title: string;
  color: string;
}

const SectionTitleMenu = ({ title, color }: SectionTitleProps) => {
  return (
    <SectionTitleContainer variant="h6" color={color}>
      {title}
    </SectionTitleContainer>
  );
};

export default SectionTitleMenu;
